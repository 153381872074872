<template>
  <div>
    <!-- 面包屑导航区域 -->
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/welcome' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>内部转账</el-breadcrumb-item>
    </el-breadcrumb>
    <!-- 卡片视图区域 -->
    <el-card>
      <!-- 搜索与添加区域 -->
<el-form
          :inline="true"
          :model="formInline"
          ref="formInline"
          class="demo-form-inline"
          size="small"
        >
         <el-row type="flex">
          <el-col :span="9">
               <el-form-item label="账户" prop="out_account_id">    
                  <el-cascader
                    placeholder="请选择（可搜索）"
                    v-model="formInline.out_account_id"
                    :options="comList"
                    :props="{ expandTrigger: 'hover',value: 'id',label:'title',children:'account' }"
                    filterable
                    style="width:340px"
                    @focus="selectAccountId"
                  >
                  </el-cascader>
                </el-select>
              </el-form-item>
          </el-col>
          <el-col :span="9">
            <el-form-item label="日期" prop="interval_time">
              <el-date-picker
                v-model="formInline.interval_time"
                type="daterange"
                align="right"
                unlink-panels
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
                :picker-options="pickerOptions"
                value-format="yyyy-MM-dd"
                :clearable="false"
              >
              </el-date-picker> 
            </el-form-item>
          </el-col>
          <el-col :span="4">
            <el-form-item>
              <el-button type="primary" @click="onSubmit">查询</el-button>
              <el-button @click="resetForm('formInline')">重置</el-button>
            </el-form-item>
          </el-col>
      </el-row>
 </el-form>
      <el-row :gutter="20">
        <el-col :span="24">
          <el-button type="primary" @click="addFormDialog">新增</el-button>
        </el-col>
      </el-row>
<!-- <el-alert
    title="`总计：960,400 元；25笔`"
    type="info"
    show-icon
    style="margin-top:15px">
  </el-alert> -->
      <!-- 用户列表区域 -->
      <el-table :data="userList" border stripe size="small">
        <el-table-column label="日期" prop="record_time" fixed></el-table-column>
        <el-table-column label="转出账户" prop="out_acc_title.title"></el-table-column>
        <el-table-column label="转入账户" prop="into_acc_title.title"></el-table-column>
        <el-table-column label="金额" prop="account"></el-table-column>
        <el-table-column label="摘要" prop="desc"></el-table-column>
      </el-table>
      <!-- 分页区域 -->
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="queryInfo.page"
        :page-sizes="[10, 20, 50, 500]"
        :page-size="queryInfo.list_rows"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </el-card>

 <!-- 新增收支信息对话框 -->
    <el-dialog
      title="新增内部转账"
      :visible.sync="addDialogVisible"
      width="35%"
      @close="addDialogClosed"
    >
      <!-- 内容主体区 -->
      <el-form
        :model="addForm"
        :rules="addFormRules"
        ref="addFormRef"
        label-width="auto"
      >
        <el-row :gutter="20">
          <el-col :span="24"
            ><div class="grid-content bg-purple">
              <el-form-item label="支出账户" prop="out_account_id">    
                  <el-cascader
                    placeholder="请选择（可搜索）"
                    v-model="addForm.out_account_id"
                    :options="comList"
                    :props="{ expandTrigger: 'hover',value: 'id',label:'title',children:'account' }"
                    filterable
                    style="width:100%"
                    @focus="selectAccountId"
                  >
                  </el-cascader>

                </el-select>
              </el-form-item>
              <el-form-item label="转入账户" prop="into_account_id">    
                  <el-cascader
                    placeholder="请选择（可搜索）"
                    v-model="addForm.into_account_id"
                    :options="comList"
                    :props="{ expandTrigger: 'hover',value: 'id',label:'title',children:'account' }"
                    filterable
                    style="width:100%"
                    @focus="selectAccountId"
                  >
                  </el-cascader>

                </el-select>
              </el-form-item>
              <el-form-item label="时间" prop="record_time">
                <el-date-picker
      v-model="addForm.record_time"
      type="datetime"
      placeholder="选择日期时间"
      value-format="yyyy-MM-dd HH:mm:ss">
    </el-date-picker>
              </el-form-item>
              <el-form-item label="交易金额" prop="account">
            <el-input
              v-model.number="addForm.account"
              clearable
            >
              <template slot="prepend">￥</template>
            </el-input>
          </el-form-item>
          <el-form-item label="备注" prop="desc">
                <el-input v-model="addForm.desc"></el-input>
              </el-form-item>
          </div></el-col>
        </el-row>
      </el-form>
      <!-- 底部区域 -->
      <span slot="footer" class="dialog-footer">
        <el-button @click="addDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="addFormList">确 定</el-button>
      </span>
    </el-dialog>

  </div>
</template>

<script>
export default {
  data() {
    return {
      formInline: {
        out_account_id: "",
        interval_time: "",
        page: 1,
        list_rows: 10,
      },
      //分支类型列表
      labelList: [],
      //账户列表
      comList: [],
      //公司列表
      deptList: [],
      //时间选择器便捷选择功能
      pickerOptions: {
        shortcuts: [
          {
            text: "最近一周",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近一个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近三个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
              picker.$emit("pick", [start, end]);
            },
          },
        ],
      },

      queryInfo: {
        page: 1,
        list_rows: 10,
      },
      userList: [],
      total: 0,
      //控制添加用户对话框显示与隐藏
      addDialogVisible: false,
      //添加数据表单
      addForm: {
        out_account_id: "",
        into_account_id: "",
        record_time: "",
        account: "",
        desc: "",
      },
      headers: {
        Authorization: window.sessionStorage.getItem("token"),
        "Content-Type": "multipart/form-data", //配置header，我们上传的文件编码格式就是multipart/form-data
      },
      //添加表单验证规则
      addFormRules: {
        out_account_id: [
          { required: true, message: "请选择收款银行", trigger: "blur" },
        ],
        into_account_id: [
          { required: true, message: "请选择收款银行", trigger: "blur" },
        ],
        record_time: [
          { required: true, message: "请选择时间", trigger: "blur" },
        ],
        account: [
          { required: true, message: "请填写交易金额", trigger: "blur" },
        ],
      },
    };
  },
  created() {
    this.getUserList();
  },
  methods: {
    /* 获取数据列表 */
    getUserList() {
    this.$http.get("bus/inner/list", {
        params: this.queryInfo,
      }).then((res)=>{
      this.userList = res.data;
      this.total = res.total;
      }).catch((err)=>{}); 
    },

    /* 监听list_rows改变的事件 */
    handleSizeChange(newSize) {
      this.queryInfo.list_rows = newSize;
      this.getUserList();
    },

    /* 监听页码值改变的事件 */
    handleCurrentChange(newPage) {
      this.queryInfo.page = newPage;
      this.getUserList();
    },

    /* 重置表单 */
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },


    /*新增-获取账户信息 */
    async selectAccountId() {
      const params = {
        page: 1,
        list_rows: 2000,
      };
      this.$http.get("bus/com/list", {
        params,
      }).then((res)=>{
       this.comList = res.data;
      }).catch((err)=>{}); 
    },
 

    /*监听添加用户对话框的关闭事件*/
    addDialogClosed() {
      //清除数据
      this.$refs.addFormRef.resetFields();
    },
    /* 新增-点击新增按钮，请求用户组*/
    addFormDialog() {
      this.addDialogVisible = true;
    },

    /* 点击确认按钮，新增或编辑*/
    addFormList() {
      //添加
      this.$refs.addFormRef.validate(async (valid) => {
        if (!valid) return;   
        this.addForm.out_account_id = this.addForm.out_account_id.at(-1);
        this.addForm.into_account_id = this.addForm.into_account_id.at(-1);
        //校验成功，发起请求

      this.$http.post("bus/inner/add", this.addForm).then((res)=>{
        this.$message.success("添加成功！");
        //隐藏对话框
        this.addDialogVisible = false;
        //重新获取列表数据
        this.getUserList();
        //清除数据
        this.$refs.addFormRef.resetFields();
      }).catch((err)=>{}); 
      });
    },
    /* 筛选数据列表 */
    async onSubmit() {
      const params = {
        out_account_id: this.formInline.out_account_id.at(-1),
        interval_time: this.formInline.interval_time.toString(),
        page: 1,
        list_rows: 10,
      };

      this.$http.get("bus/inner/list", {
        params,
      }).then((res)=>{
       this.userList = res.data;
        this.total = res.total;
      }).catch((err)=>{}); 
    },
  },
};
</script>

<style lang="less" scoped>
</style>
