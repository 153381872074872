import Vue from 'vue'
import App from './App.vue'
import router from './router'
import './plugins/element.js'
import { Loading, Message, MessageBox } from "element-ui"
// 导入全局样式表
import './assets/css/global.css'
// 导入字体图标
import './assets/fonts/iconfont.css'
// 引入echarts
import * as echarts from 'echarts';//引入echarts
Vue.prototype.$echarts = echarts //引入组件

import axios from 'axios'
//配置请求的根路径
axios.defaults.baseURL = 'https://coin.gxshangjia.com/cms'


// 请求超时时间
axios.defaults.timeout = 10000;

//请求拦截器增加Authorization
axios.interceptors.request.use(config => {
  config.headers.Authorization = window.sessionStorage.getItem('token')
  return config
}, error => {
  return Promise.error(error)
})

//请求拦截器-响应拦截
axios.interceptors.response.use(response => {
  if (response.status === 200) {
    return Promise.resolve(response.data)
  } else {
    return Promise.reject(response.data)
  }
}, error => { // 服务器状态码不是200的情况    
  if (error.message == "Network Error") {
    Message.error("网络连接异常")
  } else if (error.message.includes("timeout")) {
    Message.error("系统接口请求超时");
  }

  if (error.response.status) {
    var msg = error.response.data.msg
    switch (error.response.status) {
      //401：无效令牌、登陆报错
      case 401:
          Message.error({
            message:'登陆报错：'+msg, onClose: () => {
              // 清除token                    
            window.sessionStorage.clear();
              //跳转到登录页
              router.push("/login");
            }
          });
          break;
      // 403 token过期跳转登录页面                
      case 403:
        Message.error({
          message: '登录过期，请重新登录', onClose: () => {
            // 清除token                    
            window.sessionStorage.clear();
            //跳转到登录页
            router.push("/login");
          }
        });
        break;
      //500服务器内部报错
      case 500:
        Message.error(msg);
        break;
      //400请求报错,参数有误
      case 400:
        if (typeof (msg) != 'string') {
          Object.values(msg).forEach(item => {
            Message.error(item);
          });
        } else {
          Message.error(msg);
        }
        break;
      // 404请求不存在                
      case 404:
        Message.error(error.response.data.message);
        break;
    }
    return Promise.reject(error.response.data)
  }

})


Vue.prototype.$http = axios

Vue.config.productionTip = false

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
