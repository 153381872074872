<template>
  <div>
    <!-- 面包屑导航区域 -->
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/welcome' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>基础设置</el-breadcrumb-item>
      <el-breadcrumb-item>账户管理</el-breadcrumb-item>
    </el-breadcrumb>
    <!-- 卡片视图区域 -->
    <el-card>
      <el-container style="height: 500px; border: 1px solid #eee">
        <el-aside width="300px" style="background-color: rgb(238, 241, 246)">
          <el-menu :default-active="dataDetList.id + ''" @select="handleSelect">
            <el-header class="headerTitle">公司列表</el-header>
            <el-menu-item
              :index="item.id + ''"
              v-for="(item, index) in dataList"
              :key="item.id"
            >
              <span slot="title">{{ item.title }}</span>
            </el-menu-item>
          </el-menu>
          <div class="addBtnBottom">
            <el-button type="text" @click="comDialogVisible = true"
              >+ 添加公司</el-button
            >
          </div>
        </el-aside>

        <el-container>
          <el-header class="headerTitle">
            <div style="display: flex">
              <div
                style="margin-right: 20px; font-size: 20px; padding-top: 4px"
              >
                {{ dataDetList.title }}
              </div>
              <el-button
                icon="el-icon-edit"
                circle
                size="small"
                @click="editComDialog(dataDetList.title, dataDetList.id)"
              ></el-button>
            </div>

            <el-button type="primary" @click="addDialogVisible = true"
              >添加账户</el-button
            >
          </el-header>

          <!-- 内容主体区 -->
          <el-main>
            <el-descriptions
              class="margin-top"
              :title="dataItem.title"
              :column="1"
              size="small"
              v-if="dataDetList.account.length != 0"
              v-for="dataItem in dataDetList.account"
              :key="dataItem.id"
            >
              <template slot="extra">
                <!-- <el-button type="text" size="small">操作</el-button> -->
                <el-button
                  type="text"
                  size="small"
                  style="color: #a4a4a4"
                  @click="removeUserById(dataItem.id, dataItem.company_id)"
                  >删除</el-button
                >
              </template>
              <el-descriptions-item>
                <template slot="label"> 账户类别 </template>
                <el-tag
                  size="small"
                  :type="dataItem.type == 'C' ? 'success' : ''"
                  >{{ dataItem.type == "C" ? "现金账户" : "银行账户" }}</el-tag
                >
              </el-descriptions-item>
              <el-descriptions-item label="账户">{{
                dataItem.bank_num
              }}</el-descriptions-item>
              <el-descriptions-item
                label="开户行"
                v-if="dataItem.bank_name != ''"
              >
                {{ dataItem.bank_name }}</el-descriptions-item
              >
              <el-descriptions-item
                label="初始金额"
                contentStyle="margin-bottom: 26px;"
                >{{ dataItem.amount }}</el-descriptions-item
              >
            </el-descriptions>
          </el-main>
        </el-container>
      </el-container>
    </el-card>

    <!-- 添加公司对话框 -->
    <el-dialog
      :title="comDialogTitle"
      :visible.sync="comDialogVisible"
      width="36%"
      @close="comDialogClosed"
    >
      <!-- 内容主体区 -->
      <el-form
        :model="comForm"
        :rules="comFormRules"
        ref="comFormRef"
        label-width="80px"
      >
        <div class="grid-content bg-purple">
          <el-form-item label="公司名称" prop="title">
            <el-input v-model="comForm.title"></el-input>
          </el-form-item>
        </div>
      </el-form>
      <!-- 底部区域 -->
      <span slot="footer" class="dialog-footer">
        <el-button @click="comDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="comFormBtn">确 定</el-button>
      </span>
    </el-dialog>

    <!-- 添加账户对话框 -->
    <el-dialog
      :title="dialogTitle"
      :visible.sync="addDialogVisible"
      width="36%"
      @close="addDialogClosed"
    >
      <!-- 内容主体区 -->
      <el-form
        :model="addForm"
        :rules="addFormRules"
        ref="addFormRef"
        label-width="80px"
      >
        <div class="grid-content bg-purple">
          <el-form-item label="账户类别" prop="type">
            <el-radio-group v-model="addForm.type">
              <el-radio label="B">银行账户</el-radio>
              <el-radio label="C">现金账户</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="账户简称" prop="acc_title">
            <el-input v-model="addForm.acc_title"></el-input>
          </el-form-item>
          <el-form-item label="账号" prop="bank_num">
            <el-input v-model="addForm.bank_num">
            </el-input>
          </el-form-item>
          <el-form-item
            label="开户行"
            prop="bank_name"
            v-if="addForm.type == 'B'"
            :rules="[
              {
                required: addForm.type == 'B' ? true : false,
                message: '请输入开户行',
                trigger: 'blur',
              },
            ]"
          >
            <el-input v-model="addForm.bank_name"></el-input>
          </el-form-item>
          <el-form-item label="初始金额" prop="amount">
            <el-input
              v-model.number="addForm.amount"
              :disabled="addForm.id ? true : false"
              clearable
              oninput="if(value.length>15)value=value.slice(0,15)"
            >
              <template slot="prepend">￥</template>
            </el-input>
          </el-form-item>
        </div>
      </el-form>
      <!-- 底部区域 -->
      <span slot="footer" class="dialog-footer">
        <el-button @click="addDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="confirmFormBtn">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      queryInfo: {
        page: 1,
        list_rows: 10,
      },
      dataList: [],
      total: 0,

      //控制添加公司对话框显示与隐藏
      comDialogVisible: false,
      //添加公司表单
      comForm: {
        title: "",
      },
      //公司账户数据
      dataDetList: "",
      //控制添加用户对话框显示与隐藏
      addDialogVisible: false,
      //添加表单
      addForm: {
        acc_title: "",
        bank_num: "",
        bank_name: "",
        amount: "",
        type: "B",
        company_id: "",
      },

      //添加公司表单验证规则
      comFormRules: {
        title: [
          { required: true, message: "请输入公司名称", trigger: "blur" },
          {
            min: 2,
            max: 20,
            message: "名称长度在2~20个字符之间",
            trigger: "blur",
          },
        ],
      },
      //添加账户表单验证规则
      addFormRules: {
        type: [{ required: true }],
        acc_title: [
          { required: true, message: "请输入账户简称", trigger: "blur" },
          {min: 2,max: 20,message: "长度在2~20个字符之间",trigger: "blur",},
        ],
        bank_num: [
          { required: true, message: "请输入账号", trigger: "blur" },
          // { type: "number", message: "账号必须为数字值" },
        ],
        amount: [
          { required: true, message: "请输入部门初始金额", trigger: "blur" },
          { type: "number", message: "金额必须为数字值" },
        ],
      },
    };
  },
  created() {
    this.getDataList();
  },
  methods: {
    /* 获取数据列表 */
    getDataList(company_id) {
      this.$http
        .get("bus/com/list", {
          params: this.queryInfo,
        })
        .then((res) => {
          this.dataList = res.data;
          this.total = res.total;
          if (company_id) {
            //判断是否为添加后获取数据，添加后获取索引到当前添加公司
            this.handleSelect(company_id);
          } else {
            this.dataDetList = res.data[0];
            this.addForm.company_id = res.data[0].id;
          }
        })
        .catch((err) => {});
    },

    /* 监听list_rows改变的事件 */
    handleSizeChange(newSize) {
      this.queryInfo.list_rows = newSize;
      this.getDataList();
    },

    /* 监听页码值改变的事件 */
    handleCurrentChange(newPage) {
      this.queryInfo.page = newPage;
      this.getDataList();
    },

    //切换公司
    handleSelect(key) {
      this.dataList.forEach((item) => {
        if (item.id == key) {
          this.dataDetList = item;
          this.addForm.company_id = item.id;
        }
      });
    },

    /*公司--监听对话框的关闭事件*/
    comDialogClosed() {
      //清除数据
      this.$refs.comFormRef.resetFields();
      this.$delete(this.comForm, "id"); //删除属性方法
    },

    /* 公司--展示编辑对话框 */
    editComDialog(title, id) {
      this.comForm.title = title;
      this.comForm.id = id;
      this.comDialogVisible = true;
    },

    /* 公司--点击确认按钮，新增或编辑*/
    comFormBtn() {
      if (this.comForm.id) {
        //编辑
        this.$refs.comFormRef.validate((valid) => {
          if (!valid) return;
          //校验成功，发起请求
          this.$http
            .put("bus/com/edit", this.comForm)
            .then((res) => {
              this.$message.success("修改成功！");
              //隐藏对话框
              this.comDialogVisible = false;
              //重新获取用户列表数据
              this.getDataList();
            })
            .catch((err) => {});
        });
      } else {
        //添加用户
        this.$refs.comFormRef.validate((valid) => {
          if (!valid) return;
          //校验成功，发起请求
          this.$http
            .post("bus/com/set", this.comForm)
            .then((res) => {
              this.$message.success("添加成功！");
              //隐藏对话框
              this.comDialogVisible = false;
              //重新获取用户列表数据
              this.getDataList();
            })
            .catch((err) => {});
        });
      }
    },

    /*账户--监听对话框的关闭事件*/
    addDialogClosed() {
      //清除数据
      this.$refs.addFormRef.resetFields();
      this.$delete(this.addForm, "id"); //删除属性方法
    },

    /* 账户--点击确认按钮，新增或编辑 */
    confirmFormBtn() {
      if (this.addForm.type == "C") {
        this.addForm.bank_name = "";
      }
      //添加用户
      this.$refs.addFormRef.validate(async (valid) => {
        if (!valid) return;
        //校验成功，发起请求
        this.$http
          .post("bus/com/acc_set", this.addForm)
          .then((res) => {
            this.$message.success("添加成功！");
            //隐藏对话框
            this.addDialogVisible = false;
            //重新获取用户列表数据
            this.getDataList(this.addForm.company_id);
          })
          .catch((err) => {});
      });
    },

    /* 账户--根据ID删除对应数据 */
    async removeUserById(getid, company_id) {
      const confirmResult = await this.$confirm(
        "此操作将永久删除, 是否继续?",
        "提示",
        {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        }
      ).catch((err) => err);
      if (confirmResult != "confirm") {
        return this.$message.info("取消删除");
      }
      const idarr = { id: getid };
      this.$http
        .delete("bus/com/acc_del", {
          data: idarr,
        })
        .then((res) => {
          this.$message.success("删除成功！");
          //重新获取列表数据
          this.getDataList(company_id);
        })
        .catch((err) => {});
    },
  },
  computed: {
    //动态弹框标题
    dialogTitle() {
      if (this.addForm.id) {
        return "编辑账户";
      }
      return "添加账户";
    },
    comDialogTitle() {
      if (this.comForm.id) {
        return "编辑公司";
      }
      return "添加公司";
    },
    radioRequired() {
      if (this.addForm.type == "银行") {
        return true;
      }
      return false;
    },
  },
};
</script>

<style lang="less" scoped>
.top-btn {
  display: flex;
  justify-content: space-between;
}
.tip-alert {
  margin-bottom: 15px;
}
.headerTitle {
  height: 60px;
  font-size: 14px;
  display: flex;
  background-color: rgb(255, 255, 255);
  align-items: center;
  font-weight: 600;
  justify-content: space-between;
}
.addBtnBottom {
  text-align: center;
}
</style>
