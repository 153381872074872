<template>
  <el-row :gutter="20">
    <el-col :span="24">
      <el-card>
        <div slot="header" class="clearfix">
          <div>
            <span class="header_title">部门收支情况</span>
            <div class="header_title_tip">默认显示当天数据，可进行日期筛选</div>
          </div>

          <el-date-picker
            v-model="dateValue"
            type="daterange"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            style="float: right"
            value-format="timestamp"
            @change="onChangeDate"
            size="small"
            :default-time="['00:00:00', '23:59:59']"
          >
            >
          </el-date-picker>
        </div>
        <div v-if="deptData.length !== 0">
          <div id="myChart" :style="{ width: '100%', height: '300px' }"></div>

          <el-row :gutter="20">
            <el-col
              :xs="24"
              :sm="12"
              :md="8"
              :lg="6"
              :xl="6"
              v-for="item in deptData"
            >
              <div class="dept_box">
                <div class="dept_name">{{ item.title }}</div>
                <div class="dept_amount">
                  {{ transMoney(item.now_balance) }}
                </div>
                <div class="dept_trend">
                  <div class="income">
                    收入
                    <div class="amount_num">
                      {{ transMoney(item.inc_total) }}
                    </div>
                  </div>
                  <div class="expenditure">
                    支出
                    <div class="amount_num">
                      {{ transMoney(item.exp_total) }}
                    </div>
                  </div>
                </div>
              </div>
            </el-col>
          </el-row>
        </div>
        <div v-else class="none_data">暂无数据</div>
      </el-card>
    </el-col>

    <el-col :span="24">
      <el-card class="card_box">
        <div slot="header" class="clearfix">
          <span class="header_title">公司账户情况</span>
        </div>
        <el-row :gutter="20">
          <el-col
            :xs="24"
            :sm="12"
            :md="12"
            :lg="8"
            :xl="8"
            v-for="comitem in companyData"
          >
            <div class="dept_box box-height">
              <div class="dept_name">账户总额</div>
              <div class="dept_amount">
                {{ transMoney(comitem.today_balance) }}
              </div>
              <div class="dept_trend">
                <div class="income">
                  周同比
                  <div class="amount_num">
                    {{
                      transWeekPercent(
                        comitem.today_balance,
                        comitem.week_balance
                      )
                    }}%
                  </div>
                </div>
                <div class="expenditure">
                  日环比
                  <div class="amount_num">
                    {{
                      transTodayPercent(
                        comitem.today_balance,
                        comitem.yesterday_balance
                      )
                    }}%
                  </div>
                </div>
              </div>

              <div>
                <div class="com_title">{{ comitem.title }}</div>
                 <div class="com_box wrap-scrollbar">
                <div v-if="comitem.account.length > 0">
                  <div
                    class="com_item"
                    v-for="(accitem, index) in comitem.account"
                  >
                    <div class="com_item_list">
                      <div class="com_item_left">
                        <div class="com_item_icon">{{ index + 1 }}</div>
                        <div class="com_item_title">{{ accitem.title }}</div>
                      </div>
                      <div class="com_item_num">
                        {{ transMoney(accitem.amount) }}
                      </div>
                    </div>
                  </div>
                  
                </div>
                <div v-else class="none-tip">暂无账户</div>

              </div>
              </div>
            </div>
          </el-col>
        </el-row>
      </el-card>
    </el-col>
  </el-row>
</template>

<script>
export default {
  data() {
    return {
      chartData: "",
      deptData: "",
      companyData: "",
      dateValue: "",
    };
  },
  created() {
    this.getIndexDept();
    this.getIndexCompany();
  },

  methods: {
    //获取部门统计信息
    getIndexDept(value) {
      const params = { interval_time: value ? value : "" };
      this.$http
        .get("bus/index/dept", {
          params,
        })
        .then((res) => {
          this.chartData = res.result.chart;
          let arr = Object.values(res.result); //对象转数组
          this.deptData = arr.slice(0, -1); //去除数组数据中最后一项
          //判断是否有数据展示，再加载图表
          if (this.deptData.length != 0) {
            setTimeout(() => {
              this.drawLine();
            }, 200);
          }
        })
        .catch((err) => {});
    },

    drawLine() {
      // 基于准备好的dom，初始化echarts实例
      let myChart = this.$echarts.init(document.getElementById("myChart"));

      // 指定图表的配置项和数据
      myChart.setOption({
        title: { text: "收支趋势" },
        tooltip: {},
        grid: {
          left: "1%",
          right: "4%",
          bottom: "6%",
          top: "25%",
          containLabel: true,
        },
        legend: {
          data: ["支出", "收入"],
        },

        xAxis: {
          data: this.chartData.title,
        },
        yAxis: {},
        series: [
          {
            name: "收入",
            type: "bar",
            barWidth: 40,
            itemStyle: {
              // 柱状图颜色
              color: "#3ba1ff",
            },
            data: this.chartData.inc_data,
          },
          {
            name: "支出",
            type: "line",
            itemStyle: {
              // 折线图颜色
              color: "#ffa203",
            },
            data: this.chartData.exp_data,
          },
        ],
      });
    },

    //选择日期格式更改并进行筛选
    onChangeDate(value) {
      //value有值为选中，null为清除
      if (value) {
        let formatDate = [];
        for (let i = 0; i < value.length; i++) {
          formatDate.push(value[i] / 1000);
        }
        this.getIndexDept(formatDate.toString());
      } else {
        this.getIndexDept();
      }
    },

    //获取公司账户情况
    getIndexCompany() {
      this.$http
        .get("bus/index/company")
        .then((res) => {
          this.companyData = res.result;
        })
        .catch((err) => {});
    },

    //数字装换成金额
    transMoney(numdata) {
      let value = numdata / 100;
      if (value === 0) {
        return parseFloat(value).toFixed(2);
      }
      if (value != "") {
        var num = "";
        value += ""; //转化成字符串
        value = parseFloat(value.replace(/,/g, "")).toFixed(2); //若需要其他小数精度，可将2改成变量
        if (value.indexOf(".") == -1) {
          num = value.replace(/\d{1,3}(?=(\d{3})+$)/g, function (s) {
            return s + ",";
          });
        } else {
          num = value.replace(/(\d)(?=(\d{3})+\.)/g, function (s) {
            return s + ",";
          });
        }
      } else {
        num = "";
      }
      return num;
    },

    //周同比算法
    transWeekPercent(today, week) {
      if (week != 0) {
        var percentNum = (((today - week) / week) * 100).toFixed(2);
        return percentNum;
      }
      return 0;
    },
    //日环比算法
    transTodayPercent(today, yesterday) {
      if (yesterday != 0) {
        var percentNum = (((today - yesterday) / yesterday) * 100).toFixed(2);
        return percentNum;
      }
      return 0;
    },
  },
};
</script>
<style lang="less" scoped>
.none_data {
  padding: 20px;
  text-align: center;
}
.header_title {
  font-size: 16px;
}
.header_title_tip {
  font-size: 12px;
  color: #ccc;
}
.card_box {
  margin-top: 20px;
}
.clearfix {
  display: flex;
  justify-content: space-between;
}
.clearfix:before,
/* .clearfix:after {
  display: table;
  content: "";
} */
.clearfix:after {
  clear: both;
}

.box-height {
  height: 240px;
}
.none-tip{
  font-size: 14px;
  color: #ccc;
}
.dept_box {
  border: 1px solid #f4f4f4;
  padding: 14px 18px;
  margin: 10px 0;
  .dept_name {
    font-size: 14px;
    color: #a4a4a4;
  }

  .dept_amount {
    font-size: 24px;
    padding: 12px 0;
  }
  .dept_trend {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    .income {
      display: inline-flex;
      color: #1890ff;
      margin-right: 18px;
    }
    .expenditure {
      display: inline-flex;
      color: #ffa203;
    }
    .amount_num {
      color: #333;
      margin-left: 6px;
    }
  }
 .com_box{
    height: calc(100px);
overflow: scroll;
overflow-X: hidden;
  }
  ::-webkit-scrollbar {
  display: none;
}
  .com_title {
    font-size: 18px;
    font-weight: 600;
    padding: 20px 0;
  }
 
  .com_item {
    
    .com_item_list {
      display: flex;
      justify-content: space-between;
      margin-bottom: 10px;
      .com_item_left {
        display: flex;
      }
      .com_item_icon {
        background: #f0f2f5;
        color: #333;
        text-align: center;
        line-height: 20px;
        border-radius: 50%;
        width: 20px;
        height: 20px;
      }
      .com_item_title {
        margin-left: 10px;
      }
    }
  }
}
</style>
