import Vue from 'vue'
import {
    Button, Form, FormItem, Input, Message, Container,
    Header, Aside, Main, Menu, Submenu, MenuItem, Breadcrumb,
    BreadcrumbItem, Card, Row, Col,Table,TableColumn,Switch,
    Tag,Tooltip,Pagination,Dialog,Checkbox,CheckboxGroup,MessageBox,
    Tree,Radio,RadioGroup,Alert,Descriptions,DescriptionsItem,
    Select,Option,DatePicker,Cascader,Upload
} from 'element-ui'

Vue.use(Button)
Vue.use(Form)
Vue.use(FormItem)
Vue.use(Input)
Vue.use(Container)
Vue.use(Header)
Vue.use(Aside)
Vue.use(Main)
Vue.use(Menu)
Vue.use(Submenu)
Vue.use(MenuItem)
Vue.use(Breadcrumb)
Vue.use(BreadcrumbItem)
Vue.use(Card)
Vue.use(Row)
Vue.use(Col)
Vue.use(Table)
Vue.use(TableColumn)
Vue.use(Switch)
Vue.use(Tag)
Vue.use(Tooltip)
Vue.use(Pagination)
Vue.use(Dialog)
Vue.use(Checkbox)
Vue.use(CheckboxGroup)
Vue.use(Tree);
Vue.use(Radio);
Vue.use(RadioGroup);
Vue.use(Alert);
Vue.use(Descriptions);
Vue.use(DescriptionsItem);
Vue.use(Select);
Vue.use(Option);
Vue.use(DatePicker);
Vue.use(Cascader);
Vue.use(Upload);
Vue.prototype.$message = Message
Vue.prototype.$confirm = MessageBox.confirm