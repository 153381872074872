<template>
  <el-container class="home-container">
    <!-- 头部区域 -->
    <el-header>
      <div class="header-left">
        <div class="header-title">
          <img src="../assets/logosj.png" alt="" class="logo-img" />
          <span class="">出纳统计管理系统</span>
        </div>
        <div class="toggle-button" @click="toggleCollapse">
          <i class="el-icon-s-fold"></i>
        </div>
      </div>
      <div class="header-right">
        <div class="header-right-title">
          {{userInfo.nick_name}}
        </div>
        <el-button type="info" size="small" @click="logout">退出</el-button>
        </div>
    </el-header>
    <!-- 页面主体区域 -->
    <el-container>
      <!-- 侧边栏 -->
      <el-aside :width="isCollapse ? '64px' : '220px'">
        <el-menu
          background-color="#001529"
          text-color="#fff"
          active-text-color="#339cff"
          :unique-opened="true"
          :collapse="isCollapse"
          :collapse-transition="false"
          router
          :default-active="activePath"
        >
          <template v-for="item in menulist">
            <!-- 二级导航 -->
            <el-submenu v-if="item.child" :index="item.id + ''" :key="item.id">
              <template slot="title">
                <i :class="item.icon"></i>
                <span>{{ item.title }}</span>
              </template>
              <el-menu-item
                v-for="(items, key) in item.child"
                :key="items.id"
                :index="items.open_dir"
                @click="saveNavState(items.open_dir)"
              >
                <template slot="title">
                  <i class="el-icon-menu"></i>
                  <span>{{ items.title }}</span>
                </template>
              </el-menu-item>
            </el-submenu>
            <!-- 一级导航 -->
            <el-menu-item
              v-else
              :index="item.open_dir"
              :key="item.id"
              @click="saveNavState(item.open_dir)"
            >
              <i :class="item.icon"></i>
              <span>{{ item.title }}</span>
            </el-menu-item>
          </template>
        </el-menu>
      </el-aside>
      <!-- 右侧内容主体区域 -->
      <el-main>
        <!-- 路由占位符 -->
        <router-view></router-view>
      </el-main>
    </el-container>
  </el-container>
</template>

<script>
export default {
  data() {
    return {
      //左侧菜单数据
      menulist: [],
      //是否折叠
      isCollapse: false,
      //被激活的链接地址
      activePath: "",
      userInfo:''
    };
  },
  created() {
    this.getMenuList();
    this.getUserInfo();
    this.activePath = window.sessionStorage.getItem("activePath");
  },
  methods: {
    logout() {
      //清空token
      window.sessionStorage.clear();
      //跳转到登录页
      this.$router.push("/login");
    },
    //获取菜单
    getMenuList() {
      this.$http.get("cover/menu_list").then((res)=>{
        this.menulist = res.result;
      }).catch((err)=>{}); 
    },
    //获取登录用户信息
    getUserInfo() {
      this.$http.get("cover/user_info").then((res)=>{
        this.userInfo = res.result;
      }).catch((err)=>{}); 
    },
    //点击按钮切换菜单折叠与展开
    toggleCollapse() {
      this.isCollapse = !this.isCollapse;
    },
    //保存链接的激活状态
    saveNavState(activePath) {
      window.sessionStorage.setItem("activePath", activePath);
      this.activePath = activePath;
    },
  },
};
</script>

<style lang='less' scoped>
.el-header {
  background-color: #00284d;
  display: flex;
  justify-content: space-between;
  padding-left: 10px;
  align-items: center;
  color: #fff;
  font-size: 18px;
  .header-left {
    display: flex;
    align-items: center;
  }
  .header-title {
    width: 200px;
    display: flex;
    align-items: center;
    span {
      padding-left: 6px;
      font-weight: 600;
    }
  }
  .header-right{
    display: flex;
    align-items: center;
  }
  .header-right-title{
    font-size: 14px;
    padding-right: 20px;
  }
}
.el-aside {
  background-color: #313743;
  .el-menu {
    border-right: none;
  }
}
.el-main {
  background-color: #e9edf1;
}
.home-container {
  height: 100%;
}
.logo-img {
  height: 34px;
  width: 34px;
}
.toggle-button {
  cursor: pointer;
  padding: 10px;
}
</style>