<template>
  <div>
    <!-- 面包屑导航区域 -->
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/welcome' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>基础设置</el-breadcrumb-item>
      <el-breadcrumb-item>收支类别</el-breadcrumb-item>
    </el-breadcrumb>
    <!-- 卡片视图区域 -->
    <el-card>
      <!-- 搜索与添加区域 -->
      <el-row :gutter="20">
        <el-col :span="20">
          <el-button type="primary" @click="addDialogVisible = true"
            >添加</el-button
          >
        </el-col>
      </el-row>

      <!-- 用户列表区域 -->
      <el-table
        :data="userList"
        border
        stripe
        size="small"
        row-key="id"
        :tree-props="{ children: 'children', hasChildren: 'hasChildren' }"
        >>
        <el-table-column label="ID" prop="id" width="80"></el-table-column>

        <el-table-column
          label="收支类别"
          prop="status"
          width="120"
          align="center"
        >
          <template slot-scope="scope">
            <el-tag
              type="primary"
              disable-transitions
              v-if="scope.row.attr.attr === 'nincone'"
              >{{ scope.row.attr.title }}</el-tag
            >
            <el-tag
              type="primary"
              disable-transitions
              v-else-if="scope.row.attr.attr === 'pub_incone'"
              >{{ scope.row.attr.title }}</el-tag
            >
            <el-tag type="warning" disable-transitions v-else>{{
              scope.row.attr.title
            }}</el-tag>
          </template>
        </el-table-column>
        <el-table-column label="收支类型" prop="title"></el-table-column>
        <el-table-column label="备注" prop="desc"></el-table-column>
        <el-table-column label="创建时间" prop="create_time"></el-table-column>
        <el-table-column label="更新时间" prop="update_time"></el-table-column>
        <el-table-column label="操作" width="180px">
          <template slot-scope="scope">
            <el-button
              type="primary"
              icon="el-icon-edit"
              size="mini"
              @click="showEditDialog(scope.row.id)"
            ></el-button>
            <el-button
              type="danger"
              icon="el-icon-delete"
              size="mini"
              @click="removeUserById(scope.row.id)"
            ></el-button>
          </template>
        </el-table-column>
      </el-table>
      <!-- 分页区域 -->
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="queryInfo.page"
        :page-sizes="[10, 20, 30, 40]"
        :page-size="queryInfo.list_rows"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </el-card>
    <!-- 添加用户对话框 -->
    <el-dialog
      :title="addForm.id ? '修改收支类型' : '添加收支类型'"
      :visible.sync="addDialogVisible"
      width="36%"
      @close="addDialogClosed"
    >
      <!-- 内容主体区 -->
      <el-form
        :model="addForm"
        :rules="addFormRules"
        ref="addFormRef"
        label-width="80px"
      >
        <div class="grid-content bg-purple">
          <el-form-item label="收支类别" prop="attr">
            <el-radio-group v-model="addForm.attr">
              <el-radio label="nincone">收入</el-radio>
              <el-radio label="nexpend">支出</el-radio>
              <el-radio label="pub_incone">公共收入</el-radio>
              <el-radio label="pub_expend">公共支出</el-radio>
            </el-radio-group>
          </el-form-item>

          <el-form-item label="上级分类" prop="pid">
            <el-select
              v-model="addForm.pid"
              placeholder="不选择则默认顶级"
              filterable
              style="width: 100%"
              clearable
              @focus="selectLabelId(addForm.attr)"
            >
              <el-option
                v-for="item in comList"
                :key="item.id"
                :label="item.title"
                :value="item.id"
              ></el-option>
            </el-select>
          </el-form-item>

          <el-form-item label="类型名称" prop="title">
            <el-input v-model="addForm.title"></el-input>
          </el-form-item>
          <el-form-item label="备注" prop="desc">
            <el-input v-model="addForm.desc"></el-input>
          </el-form-item>
        </div>
      </el-form>
      <!-- 底部区域 -->
      <span slot="footer" class="dialog-footer">
        <el-button @click="addDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="confirmFormBtn">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      queryInfo: {
        // search: "",
        page: 1,
        list_rows: 10,
      },
      userList: [],
      total: 0,
      comList: [],
      //规则树对应数据名称
      defaultProps: {
        children: "children",
        label: "title",
      },
      //控制添加用户对话框显示与隐藏
      addDialogVisible: false,
      //添加用户表单
      addForm: {
        attr: "nincone",
        title: "",
        desc: "",
        pid: "",
      },

      //添加表单验证规则
      addFormRules: {
        attr: [{ required: true }],
        title: [
          { required: true, message: "请输入收支类型名称", trigger: "blur" },
          {
            min: 2,
            max: 10,
            message: "类型名称长度在2~10个字符之间",
            trigger: "blur",
          },
        ],
      },
    };
  },
  created() {
    this.getDataList();
  },
  methods: {
    /* 获取数据列表 */
    getDataList() {
      this.$http
        .get("bus/label/list", {
          params: this.queryInfo,
        })
        .then((res) => {
          this.userList = res.data;
          this.total = res.total;
        })
        .catch((err) => {});
    },

    /* 监听list_rows改变的事件 */
    handleSizeChange(newSize) {
      this.queryInfo.list_rows = newSize;
      this.getDataList();
    },

    /* 监听页码值改变的事件 */
    handleCurrentChange(newPage) {
      this.queryInfo.page = newPage;
      this.getDataList();
    },

    /*监听对话框的关闭事件*/
    addDialogClosed() {
      //清除数据
      this.treeCheckId = [];
      this.$refs.addFormRef.resetFields();
      this.$delete(this.addForm, "id"); //删除属性方法
    },

    /* 展示编辑对话框 */
    async showEditDialog(id) {
      this.addDialogVisible = true;
      this.$http
        .get("bus/label/edit" + "?id=" + id)
        .then((res) => {
          this.$delete(res.result, "create_time"); //删除属性方法
          this.$delete(res.result, "update_time"); //删除属性方法
          this.$delete(res.result, "delete_time"); //删除属性方法
          this.addForm = res.result;
        })
        .catch((err) => {});
    },
    /*新增-获取分类信息 */
    selectLabelId(attr) {
      const params = {
        page: 1,
        list_rows: 2000,
        attr:attr
      };
      this.$http
        .get("bus/label/list", {
          params,
        })
        .then((res) => {
          this.comList = res.data;
        })
        .catch((err) => {});

    },

    /* 点击确认按钮，新增或编辑*/
    confirmFormBtn() {
      if (this.addForm.pid == "") {
        this.addForm.pid = 0;
      }
      if (this.addForm.id) {
        //编辑用户
        this.$refs.addFormRef.validate((valid) => {
          if (!valid) return;
          //校验成功，发起请求
          this.$http
            .put("bus/label/edit", this.addForm)
            .then((res) => {
              this.$message.success("修改成功！");
              //隐藏对话框
              this.addDialogVisible = false;
              //重新获取用户列表数据
              this.getDataList();
            })
            .catch((err) => {});
        });
      } else {
        //添加用户
        this.$refs.addFormRef.validate((valid) => {
          if (!valid) return;
          //校验成功，发起请求
          this.$http
            .post("bus/label/set", this.addForm)
            .then((res) => {
              this.$message.success("添加成功！");
              //隐藏对话框
              this.addDialogVisible = false;
              //重新获取用户列表数据
              this.getDataList();
            })
            .catch((err) => {});
        });
      }
    },

    /* 根据ID删除对应数据 */
    async removeUserById(getid) {
      const confirmResult = await this.$confirm(
        "此操作将永久删除, 是否继续?",
        "提示",
        {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        }
      ).catch((err) => err);
      if (confirmResult != "confirm") {
        return this.$message.info("取消删除");
      }
      const idarr = { id: getid };
      this.$http
        .delete("bus/label/remove", {
          data: idarr,
        })
        .then((res) => {
          this.$message.success("删除成功！");
          //重新获取列表数据
          this.getDataList();
        })
        .catch((err) => {});
    },
  },
};
</script>

<style lang="less" scoped>
</style>
