<template>
  <div>
    <!-- 面包屑导航区域 -->
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/welcome' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>系统管理</el-breadcrumb-item>
      <el-breadcrumb-item>角色管理</el-breadcrumb-item>
    </el-breadcrumb>
    <!-- 卡片视图区域 -->
    <el-card>
      <!-- 搜索与添加区域 -->
      <el-row :gutter="20">
        <el-col :span="20">
          <el-button type="primary" @click="addFormDialog">添加角色</el-button>
        </el-col>
      </el-row>

      <!-- 用户列表区域 -->
      <el-table :data="userList" border stripe size="small">
        <el-table-column label="ID" prop="id" width="60"></el-table-column>
        <el-table-column label="角色名称" prop="title"></el-table-column>
        <el-table-column label="状态" prop="status" width="80" align="center">
          <template slot-scope="scope">
            <el-switch
              v-model="scope.row.status"
              :active-value="1"
              :inactive-value="0"
              @change="userStateChanged(scope.row)"
            ></el-switch>
          </template>
        </el-table-column>
        <el-table-column
          label="节点标识"
          prop="rules"
          width="540"
        ></el-table-column>
        <el-table-column label="创建时间" prop="create_time"></el-table-column>
        <el-table-column label="更新时间" prop="update_time"></el-table-column>
        <el-table-column label="操作" width="180px">
          <template slot-scope="scope">
            <el-button
              type="primary"
              icon="el-icon-edit"
              size="mini"
              @click="showEditDialog(scope.row.id)"
            ></el-button>
            <el-button
              type="danger"
              icon="el-icon-delete"
              size="mini"
              @click="removeUserById(scope.row.id)"
            ></el-button>
          </template>
        </el-table-column>
      </el-table>
      <!-- 分页区域 -->
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="queryInfo.page"
        :page-sizes="[10, 20, 30, 40]"
        :page-size="queryInfo.list_rows"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </el-card>
    <!-- 添加用户对话框 -->
    <el-dialog
      :title="addForm.id ? '修改角色' : '添加角色'"
      :visible.sync="addDialogVisible"
      width="36%"
      @close="addDialogClosed"
    >
      <!-- 内容主体区 -->
      <el-form
        :model="addForm"
        :rules="addFormRules"
        ref="addFormRef"
        label-width="80px"
      >
        <div class="grid-content bg-purple">
          <el-form-item label="角色名称" prop="title">
            <el-input v-model="addForm.title"></el-input>
          </el-form-item>
          <el-form-item label="用户状态" prop="status">
            <el-switch
              v-model="addForm.status"
              :active-value="1"
              :inactive-value="0"
            ></el-switch>
          </el-form-item>

          <el-form-item label="权限规则" prop="rules">
            <el-tree
              :data="treeList"
              show-checkbox
              default-expand-all
              :props="defaultProps"
              node-key="id"
              :default-checked-keys="treeCheckId"
              ref="treeRef"
            >
            </el-tree>
          </el-form-item>
        </div>
      </el-form>
      <!-- 底部区域 -->
      <span slot="footer" class="dialog-footer">
        <el-button @click="addDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="confirmFormBtn">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      queryInfo: {
        // search: "",
        page: 1,
        list_rows: 10,
      },
      userList: [],
      total: 0,
      //接口获取的角色规则树信息
      treeList: [],
      //规则树对应数据名称
      defaultProps: {
        children: "children",
        label: "title",
      },
      //规则树选中数组
      treeCheckId: [],
      //控制添加用户对话框显示与隐藏
      addDialogVisible: false,
      //添加用户表单
      addForm: {
        title: "",
        status: 1,
        rules: "",
      },

      //添加表单验证规则
      addFormRules: {
        title: [
          { required: true, message: "请输入角色名称", trigger: "blur" },
          {
            min: 2,
            max: 10,
            message: "角色名称长度在2~10个字符之间",
            trigger: "blur",
          },
        ],
        rules: [
          {
            required: true,
            message: "请至少选择一个权限类型",
            trigger: "blur",
          },
        ],
      },
    };
  },
  created() {
    this.getDataList();
  },
  methods: {
    /* 获取数据列表 */
    getDataList() {
      this.$http
        .get("cover/group/list", {
          params: this.queryInfo,
        })
        .then((res) => {
          this.userList = res.data;
          this.total = res.total;
        })
        .catch((err) => {});
    },

    /* 监听list_rows改变的事件 */
    handleSizeChange(newSize) {
      this.queryInfo.list_rows = newSize;
      this.getDataList();
    },

    /* 监听页码值改变的事件 */
    handleCurrentChange(newPage) {
      this.queryInfo.page = newPage;
      this.getDataList();
    },

    /* 监听列表Switch状态并请求更改 */
    userStateChanged(userinfo) {
      this.$http
        .put("cover/group/edit", {
          status: userinfo.status,
          id: userinfo.id,
          title: userinfo.title,
          rules: userinfo.rules,
        })
        .then((res) => {
          this.getDataList();
          this.$message.success("更新状态成功！");
        })
        .catch((err) => {
          //还原状态值
          userinfo.status = userinfo.status == 0 ? 1 : 0;
        });
    },

    /*新增-获取角色规则树 */
    getTreeList() {
      this.$http
        .get("cover/group/rule_tree")
        .then((res) => {
          this.treeList = res.result;
        })
        .catch((err) => {});
    },

    /*监听对话框的关闭事件*/
    addDialogClosed() {
      //清除数据
      this.treeCheckId = [];
      this.$refs.addFormRef.resetFields();
      this.$delete(this.addForm, "id"); //删除属性方法
    },

    /* 新增-点击新增按钮，请求规则树*/
    addFormDialog() {
      this.getTreeList();
      this.addDialogVisible = true;
    },

    /* 展示编辑对话框 */
    showEditDialog(id) {
      this.getTreeList();
      this.addDialogVisible = true;
      this.$http
        .get("cover/group/edit" + "?id=" + id)
        .then((res) => {
          this.$delete(res.result, "create_time"); //删除属性方法
          this.$delete(res.result, "update_time"); //删除属性方法
          this.addForm = res.result;
          console.log(res.result.rules);
          let rules = res.result.rules.split(",").map(Number); //.split(',')字符串转数组.map(Number)转数字
          console.log(rules);
          this.treeCheckId = rules;
        })
        .catch((err) => {});
    },

    /* 点击确认按钮，新增或编辑*/
    confirmFormBtn() {
      const keys = [
        ...this.$refs.treeRef.getCheckedKeys(),//选中的树形id，不带主id值
        // ...this.$refs.treeRef.getHalfCheckedKeys(),
      ];
      this.addForm.rules = keys.join(",");
      if (this.addForm.id) {
        //编辑用户
        this.$refs.addFormRef.validate((valid) => {
          if (!valid) return;
          //校验成功，发起请求
          this.$http
            .put("cover/group/edit", this.addForm)
            .then((res) => {
              this.$message.success("修改成功！");
              //隐藏对话框
              this.addDialogVisible = false;
              //重新获取用户列表数据
              this.getDataList();
              //清除数据
              this.treeCheckId = [];
              this.$refs.addFormRef.resetFields();
            })
            .catch((err) => {});
        });
      } else {
        //添加用户
        this.$refs.addFormRef.validate((valid) => {
          if (!valid) return;
          //校验成功，发起请求
          this.$http
            .post("cover/group/add", this.addForm)
            .then((res) => {
              this.$message.success("添加成功！");
              //隐藏对话框
              this.addDialogVisible = false;
              //重新获取用户列表数据
              this.getDataList();
              //清除数据
              this.treeCheckId = [];
              this.$refs.addFormRef.resetFields();
            })
            .catch((err) => {});
        });
      }
    },

    /* 根据ID删除对应数据 */
    async removeUserById(getid) {
      const confirmResult = await this.$confirm(
        "此操作将永久删除, 是否继续?",
        "提示",
        {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        }
      ).catch((err) => err);
      if (confirmResult != "confirm") {
        return this.$message.info("取消删除");
      }
      const idarr = { id: getid };
      this.$http
        .delete("cover/group/remove", {
          data: idarr,
        })
        .then((res) => {
          this.$message.success("删除成功！");
          //重新获取列表数据
          this.getDataList();
        })
        .catch((err) => {});
    },
  },
};
</script>

<style lang="less" scoped>
</style>
