import Vue from 'vue'
import VueRouter from 'vue-router'
import Login from '../components/Login.vue'
import Home from '../components/Home.vue'
import Welcome from '../components/Welcome.vue'
import User from '../components/system/User.vue'
import Group from '../components/system/Group.vue'
import Lable from '../components/base/Lable.vue'
import Department from '../components/base/Department.vue'
import Account from '../components/base/Account.vue'
import Checkin from '../components/base/Checkin.vue'
import AccountSum from '../components/base/AccountSum.vue'
import DepartmentSum from '../components/base/DepartmentSum.vue'
import Transfer from '../components/base/Transfer.vue'
import InnerList from '../components/base/InnerList.vue'

Vue.use(VueRouter)

const routes = [
  { path: '/', redirect: '/login' },
  { path: '/login', component: Login },
  {
    path: '/home', component: Home, redirect: '/welcome', children: [
      { path: '/welcome', component: Welcome },
      { path: '/user', component: User },
      { path: '/group', component: Group },
      { path: '/lable', component: Lable },
      { path: '/department', component: Department },
      { path: '/account', component: Account },
      { path: '/checkin', component: Checkin },
      { path: '/accountSum', component: AccountSum },
      { path: '/departmentSum', component: DepartmentSum },
      { path: '/transfer', component: Transfer },
      { path: '/innerList', component: InnerList },
    ]
  },
]

const router = new VueRouter({
  routes
})

// 挂载路由导航守卫
router.beforeEach((to, from, next) => {
  //to 将要访问的路径
  //from 代表从哪个路径跳转而来
  //next 是一个函数，表示放行
  //next() 放行   next('/login')  强制跳转
  if (to.path === '/login') return next();
  //获取token
  const tokenStr = window.sessionStorage.getItem('token')
  if (!tokenStr) return next('/login')
  next()
})

// 挂载路由导航守卫解决登陆失败时跳转报错问题
const RouterPush = VueRouter.prototype.push
VueRouter.prototype.push = function push (to) {
  return RouterPush.call(this, to).catch(err => err)
}
const RouterReplace = VueRouter.prototype.replace
VueRouter.prototype.replace = function replace (to) {
  return RouterReplace.call(this, to).catch(err => err)
}


export default router
