<template>
  <div>
    <!-- 面包屑导航区域 -->
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/welcome' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>系统管理</el-breadcrumb-item>
      <el-breadcrumb-item>管理员列表</el-breadcrumb-item>
    </el-breadcrumb>
    <!-- 卡片视图区域 -->
    <el-card>
      <!-- 搜索与添加区域 -->
      <el-row :gutter="20">
        <el-col :span="6">
          <!-- 搜索与添加区域 -->
          <el-input placeholder="请输入内容" v-model="queryInfo.search">
            <el-button
              slot="append"
              icon="el-icon-search"
              @click="getUserList"
            ></el-button>
          </el-input>
        </el-col>
        <el-col :span="5">
          <el-button type="primary" @click="addFormDialog">添加用户</el-button>
        </el-col>
      </el-row>

      <!-- 用户列表区域 -->
      <el-table :data="userList" border stripe size="small">
        <el-table-column type="index"></el-table-column>
        <el-table-column label="姓名" prop="name"></el-table-column>
        <el-table-column label="手机号" prop="tel"></el-table-column>
        <el-table-column label="用户名" prop="nick_name"></el-table-column>
        <el-table-column label="邮箱" prop="email"></el-table-column>
        <el-table-column label="职位" prop="position"></el-table-column>
        <el-table-column label="状态" prop="status">
          <template slot-scope="scope">
            <el-switch
              v-model="scope.row.status"
              active-value="Y"
              inactive-value="N"
              @change="userStateChanged(scope.row)"
            ></el-switch>
          </template>
        </el-table-column>
        <el-table-column label="创建时间" prop="create_time"></el-table-column>
        <el-table-column label="操作" width="180px">
          <template slot-scope="scope">
            <el-button
              type="primary"
              icon="el-icon-edit"
              size="mini"
              @click="showEditDialog(scope.row.id)"
            ></el-button>
            <el-button
              type="danger"
              icon="el-icon-delete"
              size="mini"
              @click="removeUserById(scope.row.id)"
            ></el-button>

            <!-- <el-tooltip
              effect="dark"
              content="分配角色"
              placement="top"
              :enterable="false"
            >
              <el-button
                type="warning"
                icon="el-icon-setting"
                size="mini"
              ></el-button>
            </el-tooltip> -->
          </template>
        </el-table-column>
      </el-table>
      <!-- 分页区域 -->
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="queryInfo.page"
        :page-sizes="[10, 20, 30, 40]"
        :page-size="queryInfo.list_rows"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </el-card>
    <!-- 添加用户对话框 -->
    <el-dialog
      :title="addForm.id ? '修改用户' : '添加用户'"
      :visible.sync="addDialogVisible"
      width="50%"
      @close="addDialogClosed"
    >
      <!-- 内容主体区 -->
      <el-form
        :model="addForm"
        :rules="addFormRules"
        ref="addFormRef"
        label-width="80px"
      >
        <el-row :gutter="20">
          <el-col :span="12"
            ><div class="grid-content bg-purple">
              <el-form-item label="昵称" prop="nick_name">
                <el-input v-model="addForm.nick_name"></el-input>
              </el-form-item>
              <el-form-item label="姓名" prop="name">
                <el-input v-model="addForm.name"></el-input>
              </el-form-item>
              <el-form-item label="手机" prop="tel">
                <el-input v-model="addForm.tel"></el-input>
              </el-form-item>
              <el-form-item label="邮箱" prop="email">
                <el-input v-model="addForm.email"></el-input>
              </el-form-item>
              <el-form-item label="职位" prop="position">
                <el-input v-model="addForm.position"></el-input>
              </el-form-item></div
          ></el-col>
          <el-col :span="12"
            ><div class="grid-content bg-purple">
              <el-form-item label="角色类型" prop="group_id">
                <el-checkbox-group
                  v-model="groupCheckId"
                  @change="handleCheckedCitiesChange"
                >
                  <el-checkbox
                    v-for="item in groupList"
                    :label="item.id"
                    :key="item.id"
                    >{{ item.title }}</el-checkbox
                  >
                </el-checkbox-group>
              </el-form-item>
              <el-form-item label="用户状态" prop="status">
                <el-switch
                  v-model="addForm.status"
                  active-value="Y"
                  inactive-value="N"
                ></el-switch>
              </el-form-item>
              <el-form-item label="密码" prop="password">
                <el-input v-model="addForm.password" type="password"></el-input>
              </el-form-item>
              <el-form-item label="确认密码" prop="repass">
                <el-input v-model="addForm.repass" type="password"></el-input>
              </el-form-item></div
          ></el-col>
        </el-row>
      </el-form>
      <!-- 底部区域 -->
      <span slot="footer" class="dialog-footer">
        <el-button @click="addDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="addUser">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    //验证邮箱规则
    var checkEmail = (rule, value, cb) => {
      const regEmail = /^([a-zA-Z0-9_-])+@([a-zA-Z0-9_-])+(.[a-zA-Z0-9_-])+/;
      if (regEmail.test(value)) {
        //合法邮箱
        return cb();
      }
      cb(new Error("请输入合法的邮箱"));
    };

    //验证手机号规则
    var checkMobile = (rule, value, cb) => {
      //验证手机号的正则表达式
      const regMobile =
        /(^((\+86)|(86))?(1[3-9])\d{9}$)|(^(0\d{2,3})-?(\d{7,8})$)/;
      if (regMobile.test(value)) {
        return cb();
      }
      cb(new Error("请输入合法的邮箱"));
    };

    //验证密码
    var validatePass2 = (rule, value, callback) => {
      if (value !== this.addForm.password) {
        callback(new Error("两次输入密码不一致!"));
      } else {
        callback();
      }
    };

    return {
      queryInfo: {
        search: "",
        page: 1,
        list_rows: 10,
      },
      userList: [],
      total: 0,
      //接口获取的角色组信息
      groupList: [],
      //角色组选中数组
      groupCheckId: [],
      //控制添加用户对话框显示与隐藏
      addDialogVisible: false,
      //添加用户表单
      addForm: {
        nick_name: "",
        name: "",
        tel: "",
        email: "",
        position: "",
        password: "",
        repass: "",
        status: "Y",
        group_id: "",
      },

      //添加表单验证规则
      addFormRules: {
        nick_name: [
          { required: true, message: "请输入昵称", trigger: "blur" },
          {
            min: 2,
            max: 10,
            message: "昵称长度在2~10个字符之间",
            trigger: "blur",
          },
        ],
        name: [
          { required: true, message: "请输入用户名", trigger: "blur" },
          {
            min: 2,
            max: 10,
            message: "用户名长度在2~10个字符之间",
            trigger: "blur",
          },
        ],
        tel: [
          { required: true, message: "请输入手机号", trigger: "blur" },
          {
            min: 11,
            max: 11,
            message: "请输入正确手机号",
            trigger: "blur",
          },
          { validator: checkMobile, trigger: "blur" },
        ],
        email: [
          { required: true, message: "请输入邮箱", trigger: "blur" },
          { validator: checkEmail, trigger: "blur" },
        ],
        position: [{ required: true, message: "请输入职位", trigger: "blur" }],
        group_id: [
          {
            required: true,
            message: "请至少选择一个角色类型",
            trigger: "blur",
          },
        ],
        password: [
          { required: true, message: "请输入密码", trigger: "blur" },
          {
            min: 6,
            max: 15,
            message: "密码长度在6~15个字符之间",
            trigger: "blur",
          },
        ],
        repass: [
          { required: true, message: "请再次输入密码", trigger: "blur" },
          {
            min: 6,
            max: 15,
            message: "密码长度在6~15个字符之间",
            trigger: "blur",
          },
          { validator: validatePass2, trigger: "blur" },
        ],
      },
    };
  },
  created() {
    this.getUserList();
  },
  methods: {
    /* 获取用户列表 */
    getUserList() {
      this.$http
        .get("cover/web_admin/list", {
          params: this.queryInfo,
        })
        .then((res) => {
          this.userList = res.data;
          this.total = res.total;
        })
        .catch((err) => {});
    },

    /* 监听list_rows改变的事件 */
    handleSizeChange(newSize) {
      this.queryInfo.list_rows = newSize;
      this.getUserList();
    },

    /* 监听页码值改变的事件 */
    handleCurrentChange(newPage) {
      this.queryInfo.page = newPage;
      this.getUserList();
    },

    /* 监听列表Switch状态并请求更改 */
    userStateChanged(userinfo) {
      this.$http
        .put("cover/web_admin/edit", {
          status: userinfo.status,
          id: userinfo.id,
          tel: userinfo.tel,
          name: userinfo.name,
        })
        .then((res) => {
          this.getUserList();
          this.$message.success("更新用户状态成功！");
        })
        .catch((err) => {
          userinfo.status = !userinfo.status;
        });
    },

    /*新增-获取角色组信息 */
    getGroupList() {
      const params = {
        page: 1,
        list_rows: 20,
      };

      this.$http
        .get("cover/group/list", {
          params,
        })
        .then((res) => {
          this.groupList = res.data;
        })
        .catch((err) => {});
    },

    /*监听复选框，转换数据类型*/
    handleCheckedCitiesChange(value) {
      let str = value.join(","); //数组转字符串
      this.addForm.group_id = str;
    },

    /*监听添加用户对话框的关闭事件*/
    addDialogClosed() {
      //清除数据
      this.groupCheckId = [];
      this.$refs.addFormRef.resetFields();
      this.$delete(this.addForm, "id"); //删除属性方法
    },
    /* 新增-点击新增按钮，请求用户组*/
    addFormDialog() {
      this.getGroupList();
      this.addDialogVisible = true;
    },

    /* 点击确认按钮，新增或编辑*/
    addUser() {
      if (this.addForm.id) {
        //编辑用户
        this.$refs.addFormRef.validate((valid) => {
          if (!valid) return;
          //校验成功，发起请求
          this.$http
            .put("cover/web_admin/edit", this.addForm)
            .then((res) => {
              this.$message.success("修改用户成功！");
              //隐藏对话框
              this.addDialogVisible = false;
              //重新获取用户列表数据
              this.getUserList();
              //清除数据
              this.groupCheckId = [];
              this.$refs.addFormRef.resetFields();
            })
            .catch((err) => {});
        });
      } else {
        //添加用户
        this.$refs.addFormRef.validate((valid) => {
          if (!valid) return;
          //校验成功，发起请求
          this.$http
            .post("cover/web_admin/add", this.addForm)
            .then((res) => {
              this.$message.success("添加用户成功！");
              //隐藏对话框
              this.addDialogVisible = false;
              //重新获取用户列表数据
              this.getUserList();
              //清除数据
              this.groupCheckId = [];
              this.$refs.addFormRef.resetFields();
            })
            .catch((err) => {
              userinfo.status = !userinfo.status;
            });
        });
      }
    },

    /* 展示编辑用户对话框 */
    async showEditDialog(id) {
      this.addDialogVisible = true;
      this.$delete(this.addFormRules.password[0], "required"); //删除密码必填
      this.$delete(this.addFormRules.repass[0], "required");
      this.$http
        .get("cover/web_admin/edit" + "?id=" + id)
        .then((res) => {
          this.$delete(res.result, "create_time"); //删除属性方法
          this.$delete(res.result, "update_time"); //删除属性方法
          this.$nextTick(() => {
            this.addForm = {
              id: res.result.id,
              nick_name: res.result.nick_name,
              name: res.result.name,
              tel: res.result.tel,
              email: res.result.email,
              position: res.result.position,
              status: res.result.status,
            };

            this.$http
              .get("cover/web_admin/user_group" + "?id=" + id)
              .then((arr) => {
                this.addForm.group_id = arr.result.str;
                this.groupList = arr.result.arr;
                let str = arr.result.str.split(",").map(Number); //.split(',')字符串转数组.map(Number)转数字
                this.groupCheckId = str;
              })
              .catch((err) => {});
          });
        })
        .catch((err) => {});
    },

    /* 根据ID删除对应数据 */
    async removeUserById(getid) {
      const confirmResult = await this.$confirm(
        "此操作将永久删除, 是否继续?",
        "提示",
        {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        }
      ).catch((err) => err);
      if (confirmResult != "confirm") {
        return this.$message.info("取消删除");
      }
      const idarr = { id: getid };
      this.$http
        .delete("cover/web_admin/remove", {
          data: idarr,
        })
        .then((arr) => {
          this.$message.success("删除成功！");
          //重新获取用户列表数据
          this.getUserList();
        })
        .catch((err) => {});
    },
  },
};
</script>

<style lang="less" scoped>
</style>
