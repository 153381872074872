<template>
  <div class="login_container">
    <div class="bg"></div>
    <div class="login_box">
      <div class="avater_box">
        <h2>出纳统计管理系统</h2>
      </div>
      <!-- 头像区域 -->
       <!-- 标题区域 -->
      <div class="title">
        <h2>账号登录</h2>
        <div class="line"></div>
      </div>
      <!-- 登录表单区域 -->
      <el-form
        label-width="0px"
        class="login_form"
        :model="loginForm"
        :rules="loginFormRules"
        ref="loginFormRef"
      >
        <!-- 用户名 -->
        <el-form-item prop="tel">
          <el-input
            prefix-icon="iconfont icon-shouji"
            v-model="loginForm.tel"
          ></el-input>
        </el-form-item>
        <!-- 密码 -->
        <el-form-item prop="password">
          <el-input
            prefix-icon="iconfont icon-yanzhengma"
            v-model="loginForm.password"
            type="password"
          ></el-input>
        </el-form-item>
        <!-- 按钮区域 -->
        <el-form-item class="btns">
          <el-button type="primary" @click="login">登录</el-button>
          <el-button type="info" @click="resetLoginForm">重置</el-button>
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      loginForm: {
        tel: "",
        password: "",
      },
      loginFormRules: {
        tel: [
          { required: true, message: "请输入登录名称", trigger: "blur" },
          {
            min: 3,
            max: 11,
            message: "长度在 3 到 11 个字符",
            trigger: "blur",
          },
        ],
        password: [
          { required: true, message: "请输入登录密码", trigger: "blur" },
          {
            min: 6,
            max: 15,
            message: "长度在 6 到 15 个字符",
            trigger: "blur",
          },
        ],
      },
    };
  },
  methods: {
    resetLoginForm() {
      this.$refs.loginFormRef.resetFields();
    },
    login() {
      this.$refs.loginFormRef.validate((valid) => {
        if (!valid) return;
        this.$http.post("cover/acc_token", this.loginForm)
          .then((res) => {
            this.$message.success("登录成功!");
            window.sessionStorage.setItem("token", res.result.access_token);
            window.sessionStorage.setItem(
              "refresh_token",
              res.result.refresh_token
            );
            this.$router.push("/home");
          })
          .catch((err) => {});
        //1.将登录成功之后的 token，保存到客户端的 sessionstorage 中
        //1.1 项目中出了登录之外的其他API接口，必须在登录之后才能访问
        //1.2 token 只应在当前网站打开期间生效，所以将 token 保存在 sessionStorage 中
        // 2.通过编程式导航跳转到后台主页，路由地址是 /home
      });
    },
  },
};
</script>
<style lang="less" scoped>
.bg {
   background:url("../assets/bg4.png");
  width: 100%;
  height: 50%;
  background-size:100% 150%;
}
.login_container {
  // background-color: #2b4b6b;
  background-color: #fff;
  width: 100%;
  height: 100%;
  background-size:100% 100%;
}
.title{
  padding: 20px;
  font-size: 12px;
}
.line{
 width: 70px;
    height: 6px;
    background: #409eff;
    margin-top: -20px;
}
.login_box {
  width: 450px;
  height: 280px;
  background-color: #fff;
  border-radius: 3px;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  box-shadow: 0 0 10px #ddd;

  .avater_box {
  padding: 10px;
    position: absolute;
    left: 50%;
    transform: translate(-50%, -100%);
    font-size: 21px;
    width: 100%;

   /*  img {
      width: 100%;
      height: 100%;
      border-radius: 50%;
      background-color: #eee;
      box-sizing: border-box;
      -moz-box-sizing: border-box;
      border: 1em solid #eeeeee;
    } */
  }
}

.login_form {
  position: absolute;
  bottom: 0;
  width: 100%;
  padding: 0 20px;
  box-sizing: border-box;
}
.btns {
  display: flex;
  justify-content: flex-end;
}
</style>