<template>
  <div>
    <!-- 面包屑导航区域 -->
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/welcome' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>基础设置</el-breadcrumb-item>
      <el-breadcrumb-item>部门管理</el-breadcrumb-item>
    </el-breadcrumb>
    <!-- 卡片视图区域 -->
    <el-card>
      <!-- 搜索与添加区域 -->
      <el-row :gutter="20">
        <el-col :span="24">
          <div class="top-btn">
            <el-button type="primary" @click="addDialogVisible = true"
              >添加部门</el-button
            >
            <el-button type="warning" @click="editAllotDialog"
              >公共支出比例调整</el-button
            >
          </div>
        </el-col>
      </el-row>

      <!-- 用户列表区域 -->
      <el-table :data="dataList" border stripe size="small">
        <el-table-column label="ID" prop="id" width="50"></el-table-column>
        <el-table-column label="部门" prop="title"></el-table-column>
        <el-table-column label="初始金额（元）" prop="amount"></el-table-column>
        <el-table-column
          label="公共支出比例（%）"
          prop="quota"
        ></el-table-column>
        <el-table-column label="创建时间" prop="create_time"></el-table-column>
        <el-table-column label="更新时间" prop="update_time"></el-table-column>
        <el-table-column label="操作" width="180px">
          <template slot-scope="scope">
            <el-button
              type="primary"
              icon="el-icon-edit"
              size="mini"
              @click="showEditDialog(scope.row.id)"
            ></el-button>
            <el-button
              type="danger"
              icon="el-icon-delete"
              size="mini"
              @click="removeUserById(scope.row.id)"
            ></el-button>
          </template>
        </el-table-column>
      </el-table>
      <!-- 分页区域 -->
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="queryInfo.page"
        :page-sizes="[10, 20, 30, 40]"
        :page-size="queryInfo.list_rows"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </el-card>
    <!-- 添加用户对话框 -->
    <el-dialog
      :title="dialogTitle"
      :visible.sync="addDialogVisible"
      width="36%"
      @close="addDialogClosed"
    >
      <!-- 内容主体区 -->
      <el-form
        :model="addForm"
        :rules="addFormRules"
        ref="addFormRef"
        label-width="80px"
      >
        <div class="grid-content bg-purple">
          <el-form-item label="部门名称" prop="title">
            <el-input v-model="addForm.title"></el-input>
          </el-form-item>
          <el-form-item label="初始金额" prop="amount">
            <el-input
              v-model.number="addForm.amount"
              :disabled="addForm.id ? true : false"
              clearable
            >
              <template slot="prepend">￥</template>
            </el-input>
          </el-form-item>
        </div>
      </el-form>
      <!-- 底部区域 -->
      <span slot="footer" class="dialog-footer">
        <el-button @click="addDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="confirmFormBtn">确 定</el-button>
      </span>
    </el-dialog>

    <!-- 公共比例调整对话框 -->
    <el-dialog
      title="公共支出比例调整"
      :visible.sync="allotDialogVisible"
      width="30%"
      @close="allotDialogClosed"
    >
      <!-- 内容主体区 -->
      <el-alert
        show-icon
        title="公共支出比例总值为100%"
        type="warning"
        :closable="false"
        class="tip-alert"
      >
      </el-alert>

      <el-form
        :model="allotForm"
        :rules="alloFormRules"
        ref="allotFormRef"
        label-width="80px"
      >
        <div class="grid-content bg-purple">
          <el-form-item
            v-for="(item, index) in allotForm.quota"
            :key="index"
            :label="item.title"
            :prop="'quota.' + index + '.quota'"
            :rules="[{ type: 'number' }]"
          >
            <el-input v-model.number="item.quota">
              <template slot="append">%</template>
            </el-input>
          </el-form-item>
        </div>
      </el-form>
      <!-- 底部区域 -->
      <span slot="footer" class="dialog-footer">
        <el-button @click="allotDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="allotConfirmFormBtn">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      queryInfo: {
        // search: "",
        page: 1,
        list_rows: 10,
      },
      dataList: [],
      total: 0,
      //规则树对应数据名称
      defaultProps: {
        children: "children",
        label: "title",
      },
      //控制添加用户对话框显示与隐藏
      addDialogVisible: false,
      //添加用户表单
      addForm: {
        title: "",
        amount: "",
      },
      //控制分配比例对话框显示与隐藏
      allotDialogVisible: false,
      //分配比例表单
      allotForm: {
        quota: [],
      },

      //添加表单验证规则
      addFormRules: {
        title: [
          { required: true, message: "请输入部门名称", trigger: "blur" },
          {
            min: 2,
            max: 10,
            message: "名称长度在2~10个字符之间",
            trigger: "blur",
          },
        ],
        amount: [
          { required: true, message: "请输入部门初始金额", trigger: "blur" },
          { type: "number", message: "金额必须为数字值" },
        ],
      },
      //分配比例验证规则
      alloFormRules: {
        quota: [{ type: "number", message: "必须为数字值" }],
      },
    };
  },
  created() {
    this.getDataList();
  },
  methods: {
    /* 获取数据列表 */
    getDataList() {
      this.$http
        .get("bus/dept/list", {
          params: this.queryInfo,
        })
        .then((res) => {
          this.dataList = res.data;
          this.total = res.total;
        })
        .catch((err) => {});
    },

    /* 监听list_rows改变的事件 */
    handleSizeChange(newSize) {
      this.queryInfo.list_rows = newSize;
      this.getDataList();
    },

    /* 监听页码值改变的事件 */
    handleCurrentChange(newPage) {
      this.queryInfo.page = newPage;
      this.getDataList();
    },

    /*监听对话框的关闭事件*/
    addDialogClosed() {
      //清除数据
      this.$refs.addFormRef.resetFields();
      this.$delete(this.addForm, "id"); //删除属性方法
    },

    /*监听分配比例对话框的关闭事件*/
    allotDialogClosed() {
      //清除数据
      this.$refs.allotFormRef.resetFields();
      this.allotForm.quota = [];
    },

    /* 展示编辑对话框 */
    showEditDialog(id) {
      this.addDialogVisible = true;
      this.$http
        .get("bus/dept/edit" + "?id=" + id)
        .then((res) => {
          this.addForm = {
            id: res.result.id,
            title: res.result.title,
            amount: parseInt(res.result.amount),
          };
        })
        .catch((err) => {});
    },

    /* 点击确认按钮，新增或编辑*/
    confirmFormBtn() {
      console.log(this.addForm.id);
      if (this.addForm.id) {
        //编辑用户
        this.$refs.addFormRef.validate((valid) => {
          if (!valid) return;
          //校验成功，发起请求
          this.$http
            .put("bus/dept/edit", this.addForm)
            .then((res) => {
              this.$message.success("修改成功！");
              //隐藏对话框
              this.addDialogVisible = false;
              //重新获取用户列表数据
              this.getDataList();
            })
            .catch((err) => {});
        });
      } else {
        //添加用户
        this.$refs.addFormRef.validate((valid) => {
          if (!valid) return;
          //校验成功，发起请求
          this.$http
            .post("bus/dept/set", this.addForm)
            .then((res) => {
              this.$message.success("添加成功！");
              //隐藏对话框
              this.addDialogVisible = false;
              //重新获取用户列表数据
              this.getDataList();
            })
            .catch((err) => {});
        });
      }
    },

    /* 根据ID删除对应数据 */
    async removeUserById(getid) {
      const confirmResult = await this.$confirm(
        "此操作将永久删除, 是否继续?",
        "提示",
        {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        }
      ).catch((err) => err);
      if (confirmResult != "confirm") {
        return this.$message.info("取消删除");
      }
      const idarr = { id: getid };
      this.$http
        .delete("bus/dept/remove", {
          data: idarr,
        })
        .then((res) => {
          this.$message.success("删除成功！");
          //重新获取列表数据
          this.getDataList();
        })
        .catch((err) => {});
    },

    /* 点击比例调整按钮，进行数据重构*/
    editAllotDialog() {
      this.dataList.forEach((item) => {
        this.allotForm.quota.push({
          title: item.title,
          id: item.id,
          quota: item.quota,
        });
      });
      this.allotDialogVisible = true;
    },

    /* 比例分配-点击确认按钮*/
    allotConfirmFormBtn() {
      let itemSum = 0;
      this.allotForm.quota.forEach((item) => {
        itemSum += item.quota;
      });
      if (itemSum != 100) {
        return this.$message.error("部门比例分配总额需等于100");
      }
      //请求接口
      this.$refs.allotFormRef.validate((valid) => {
        if (!valid) return;
        //校验成功，发起请求
        this.$http
        .post("bus/dept/quota", this.allotForm)
        .then((res) => {
          this.$message.success("设置成功！");
        //隐藏对话框
        this.allotDialogVisible = false;
        //重新获取用户列表数据
        this.getDataList();
        })
        .catch((err) => {});
      });
    },
  },
  computed: {
    //动态弹框标题
    dialogTitle() {
      if (this.addForm.id) {
        return "编辑部门";
      }
      return "添加部门";
    },
  },
};
</script>

<style lang="less" scoped>
.top-btn {
  display: flex;
  justify-content: space-between;
}
.tip-alert {
  margin-bottom: 15px;
}
</style>
