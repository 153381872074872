<template>
  <div>
    <!-- 面包屑导航区域 -->
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/welcome' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>收支管理</el-breadcrumb-item>
    </el-breadcrumb>
    <!-- 卡片视图区域 -->
    <el-card>
      <!-- 搜索与添加区域 -->
<el-form
          :inline="true"
          :model="formInline"
          ref="formInline"
          class="demo-form-inline"
          size="small"
        >
      <el-row type="flex">
        
          <el-col :span="7">
            <el-form-item label="部门" prop="dept_id">
                <el-select
                  v-model="formInline.dept_id"
                  placeholder="请选择"
                  filterable
                  style="width:100%"
                  @focus="selectDeptId"
                >
                  <el-option
                    v-for="deptItem in deptList"
                    :key="deptItem.id"
                    :label="deptItem.title"
                    :value="deptItem.id"
                  ></el-option>
                </el-select>
              </el-form-item>
          </el-col>
         
          <el-col :span="7">
             <el-form-item label="收支类别" prop="attr">
              <el-select v-model="formInline.attr" placeholder="请选择" @change="changeLabel">
                <el-option label="收入" value="nincone"></el-option>
                <el-option label="支出" value="nexpend"></el-option>
                <el-option label="公共收入" value="pub_incone"></el-option>
                <el-option label="公共支出" value="pub_expend"></el-option>
              </el-select> 
            </el-form-item>           
          </el-col>
          <el-col :span="9">

            <el-form-item label="收支类型" prop="label">
              <el-cascader
                    placeholder="请选择（可搜索）"
                    v-model="formInline.label"
                    :options="labelList"
                    :props="{ checkStrictly: true,expandTrigger: 'hover',value: 'id',label:'title',children:'children' }"
                    filterable
                    style="width:280px"
                    @focus="getGroupList(formInline.attr)"
                  >
                  </el-cascader>
              </el-form-item>
            </el-col>
        
        </el-row>

        <el-row type="flex" :gutter="20">
              <el-col :span="9">
               <el-form-item label="账户" prop="account_id">    
                  <el-cascader
                    placeholder="请选择（可搜索）"
                    v-model="formInline.account_id"
                    :options="comList"
                    :props="{ expandTrigger: 'hover',value: 'id',label:'title',children:'account' }"
                    filterable
                    style="width:320px"
                    @focus="selectAccountId"
                  >
                  </el-cascader>
                </el-select>
              </el-form-item>
          </el-col>
          <el-col :span="10">
            <el-form-item label="日期" prop="interval_time">
              <el-date-picker
                v-model="formInline.interval_time"
                type="daterange"
                align="right"
                unlink-panels
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
                :picker-options="pickerOptions"
                value-format="yyyy-MM-dd"
                 :clearable="false"
              >
              </el-date-picker> 
            </el-form-item>
          </el-col>
          <el-col :span="4">
            <el-form-item>
              <el-button type="primary" @click="onSubmit">查询</el-button>
              <el-button @click="resetForm('formInline')">重置</el-button>
            </el-form-item>
          </el-col>
       
      </el-row>
 </el-form>


      <el-row :gutter="20">
        <el-col :span="24">
          <el-button type="primary" @click="addFormDialog">新增</el-button>
          <el-button type="warning" @click="download">下载模板</el-button>
          <div style="display:inline-block;margin-left:10px">
          <el-upload
              ref="uploadplan"
              class="upload-demo"
              action=""
              accept=".xlsx, .xls"
              :http-request="uploadHttpRequest"
              :limit="1"
              :file-list="fileList"
              name="record_excel"
            >
              <el-button type="success">
                上传批量新增
              </el-button>
            </el-upload>
            </div>
         </el-col>
      </el-row>
<el-alert
    :title="`收入总计：${incTotal}元；支出总计：${expTotal}元`"
    type="info"
    show-icon
    style="margin-top:15px">
  </el-alert>
      <!-- 用户列表区域 -->
      <el-table :data="userList" border stripe size="small">
        <!-- <el-table-column type="index"></el-table-column> -->
        <el-table-column label="日期" prop="record_time" fixed></el-table-column>
        <el-table-column label="账户" prop="accounttitle.title"></el-table-column>
        <el-table-column label="部门" prop="dept.title"></el-table-column>
        <el-table-column
          label="收支类别"
          prop="label_attr"
          width="120"
          align="center"
        >
          <template slot-scope="scope">
            <el-tag
              type="primary"
              disable-transitions
              v-if="(scope.row.label_attr === 'nincone')"
              >收入</el-tag
            >
            <el-tag
              type="primary"
              disable-transitions
              v-else-if="(scope.row.label_attr === 'pub_incone')"
              >公共收入</el-tag
            >
             <el-tag
              type="warning"
              disable-transitions
              v-else-if="(scope.row.label_attr === 'nexpend')"
              >支出</el-tag
            >
            <el-tag type="warning" disable-transitions v-else>公共支出</el-tag>
          </template>
        </el-table-column>
        <el-table-column label="收支类型" prop="label.title"></el-table-column>
        <el-table-column label="往来公司" prop="other_title"></el-table-column>
        <el-table-column label="收入" prop="account">
          <template slot-scope="scope">
        <span>{{scope.row.label_attr === 'nincone'||scope.row.label_attr ==='pub_incone' ? scope.row.account : ''}}</span>
      </template>
        </el-table-column>
         <el-table-column label="支出" prop="account">
          <template slot-scope="scope">
        <span>{{scope.row.label_attr === 'nexpend'||scope.row.label_attr ==='pub_expend' ? scope.row.account : ''}}</span>
      </template>
        </el-table-column>
        <el-table-column label="摘要" prop="remark"></el-table-column>
      </el-table>
      <!-- 分页区域 -->
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="queryInfo.page"
        :page-sizes="[10, 20, 50, 500]"
        :page-size="queryInfo.list_rows"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </el-card>
    <!-- 新增收支信息对话框 -->
    <el-dialog
      title="新增收支信息"
      :visible.sync="addDialogVisible"
      width="35%"
      @close="addDialogClosed"
    >
      <!-- 内容主体区 -->
      <el-form
        :model="addForm"
        :rules="addFormRules"
        ref="addFormRef"
        label-width="auto"
      >
        <el-row :gutter="20">
          <el-col :span="24"
            ><div class="grid-content bg-purple">
              <el-form-item label="收支类别" prop="label_attr">
                <el-radio-group
                  v-model="addForm.label_attr"
                  @change="changeLabel"
                >
                  <el-radio label="nincone">收入</el-radio>
                  <el-radio label="nexpend">支出</el-radio>
                  <el-radio label="pub_incone">公共收入</el-radio>
                  <el-radio label="pub_expend">公共支出</el-radio>
                </el-radio-group>
              </el-form-item>
              <el-form-item label="收支类型" prop="label">
                <el-cascader
                    placeholder="请选择（可搜索）"
                    v-model="addForm.label"
                    :options="labelList"
                    :props="{ checkStrictly: true,expandTrigger: 'hover',value: 'id',label:'title',children:'children' }"
                    filterable
                    style="width:100%"
                    @focus="getGroupList(addForm.label_attr)"
                  >
                  </el-cascader>
              </el-form-item>
              <el-form-item label="部门" prop="dept_id" v-if="addForm.label_attr=='nincone'||addForm.label_attr=='nexpend'">
                <el-select
                  v-model="addForm.dept_id"
                  placeholder="请选择"
                  filterable
                  style="width:100%"
                  @focus="selectDeptId"
                >
                  <el-option
                    v-for="deptItem in deptList"
                    :key="deptItem.id"
                    :label="deptItem.title"
                    :value="deptItem.id"
                  ></el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="账户" prop="account_id">    
                  <el-cascader
                    placeholder="请选择（可搜索）"
                    v-model="addForm.account_id"
                    :options="comList"
                    :props="{ expandTrigger: 'hover',value: 'id',label:'title',children:'account' }"
                    filterable
                    style="width:100%"
                    @focus="selectAccountId"
                  >
                  </el-cascader>

                </el-select>
              </el-form-item>
              <el-form-item label="时间" prop="record_time">
                <el-date-picker
      v-model="addForm.record_time"
      type="datetime"
      placeholder="选择日期时间"
      value-format="yyyy-MM-dd HH:mm:ss">
    </el-date-picker>
              </el-form-item>
              <el-form-item label="往来公司" prop="other_title">
                <el-input v-model="addForm.other_title"></el-input>
              </el-form-item>
              <el-form-item label="交易金额" prop="account">
            <el-input
              v-model.number="addForm.account"
              clearable
            >
              <template slot="prepend">￥</template>
            </el-input>
          </el-form-item>
          <el-form-item label="摘要" prop="remark">
                <el-input v-model="addForm.remark"></el-input>
              </el-form-item>
          </div></el-col>
        </el-row>
      </el-form>
      <!-- 底部区域 -->
      <span slot="footer" class="dialog-footer">
        <el-button @click="addDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="addFormList">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      formInline: {
        label: "",
        attr: "",
        account_id: "",
        dept_id: "",
        interval_time: "",
        page: 1,
        list_rows: 10,
      },
      //收入总计
      expTotal: "",
      //支出总计
      incTotal: "",
      //分支类型列表
      labelList: [],
      //账户列表
      comList: [],
      //公司列表
      deptList: [],
      //时间选择器便捷选择功能
      pickerOptions: {
        shortcuts: [
          {
            text: "最近一周",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近一个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近三个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
              picker.$emit("pick", [start, end]);
            },
          },
        ],
      },

      queryInfo: {
        page: 1,
        list_rows: 10,
      },
      userList: [],
      total: 0,
      //控制添加用户对话框显示与隐藏
      addDialogVisible: false,
      //添加用户表单
      addForm: {
        label: "",
        label_attr: "nincone",
        account_id: "",
        dept_id: "",
        record_time: "",
        other_title: "",
        account: "",
        remark: "",
      },
      fileList: [], //上传的文件列表
      headers: {
        Authorization: window.sessionStorage.getItem("token"),
        "Content-Type": "multipart/form-data", //配置header，我们上传的文件编码格式就是multipart/form-data
      },
      //添加表单验证规则
      addFormRules: {
        label_attr: [{ required: true }],
        label: [{ required: true, message: "请选择收支类型", trigger: "blur" }],
        dept_id: [{ required: true, message: "请选择部门", trigger: "blur" }],
        account_id: [
          { required: true, message: "请选择收款银行", trigger: "blur" },
        ],
        record_time: [
          { required: true, message: "请选择时间", trigger: "blur" },
        ],
        other_title: [
          { required: true, message: "请填写往来公司名称", trigger: "blur" },
        ],
        account: [
          { required: true, message: "请填写交易金额", trigger: "blur" },
        ],
      },
    };
  },
  created() {
    this.getUserList();
    this.getRecordCount();
  },
  methods: {
    /* 获取数据列表 */
    getUserList() {
      this.$http.get("bus/record/list", {
        params: this.queryInfo,
      }).then((res)=>{
        this.userList = res.data;
        this.total = res.total;
      }).catch((err)=>{});   
    },

    /* 监听list_rows改变的事件 */
    handleSizeChange(newSize) {
      this.queryInfo.list_rows = newSize;
      this.getUserList();
    },

    /* 监听页码值改变的事件 */
    handleCurrentChange(newPage) {
      this.queryInfo.page = newPage;
      this.getUserList();
    },

    /* 重置表单 */
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },

    /* 获取收入统计 */
    getRecordCount(value) {
      const params = {
        label: this.formInline.label.at(-1),
        attr: this.formInline.attr,
        account_id: this.formInline.account_id.at(-1),
        dept_id: this.formInline.dept_id,
        interval_time: this.formInline.interval_time.toString(),
        page: 1,
        list_rows: 9999999,
      };

      this.$http.get("bus/record/count", {
        params,
      }).then((res)=>{
       this.incTotal = res.result.incTotal;
      this.expTotal = res.result.expTotal;
      }).catch((err)=>{}); 
    },

    /*新增-获取收支类型信息 */
    getGroupList(value) {
      if (value == "") {
        var params = {
          page: 1,
          list_rows: 2000,
        };
      } else {
        var params = {
          page: 1,
          list_rows: 2000,
          attr: value,
        };
      }

      this.$http.get("bus/label/list", {
        params,
      }).then((res)=>{
        this.labelList = res.data;
      }).catch((err)=>{});  
    },

    //单选项获取对应收支类型数据及清空已选数据
    changeLabel(value) {
      this.addForm.label = "";
      this.formInline.label = "";
      this.getGroupList(value);
    },

    /*新增-获取账户信息 */
    selectAccountId() {
      const params = {
        page: 1,
        list_rows: 2000,
      };
      this.$http.get("bus/com/list", {
        params,
      }).then((res)=>{
       this.comList = res.data;
      }).catch((err)=>{}); 
    },

    /*新增-获取分类类型信息 */
    async selectLabelId() {
      const params = {
        page: 1,
        list_rows: 2000,
      };

    this.$http.get("bus/label/list", {
        params,
      }).then((res)=>{
        this.labelList = res.data;
      }).catch((err)=>{}); 
    },
    /*新增-获取部门信息 */
    selectDeptId() {
      const params = {
        page: 1,
        list_rows: 2000,
      };

      this.$http.get("bus/dept/list", {
        params,
      }).then((res)=>{
        this.deptList = res.data;
      }).catch((err)=>{});    
    },

    /*监听添加用户对话框的关闭事件*/
    addDialogClosed() {
      //清除数据
      this.$refs.addFormRef.resetFields();
    },
    /* 新增-点击新增按钮，请求用户组*/
    addFormDialog() {
      this.addDialogVisible = true;
    },

    /* 点击确认按钮，新增或编辑*/
    addFormList() {
      
      //添加
      this.$refs.addFormRef.validate((valid) => {
        if (!valid) return;
      this.addForm.label = this.addForm.label.at(-1);
      this.addForm.account_id = this.addForm.account_id.at(-1);
        //校验成功，发起请求
        this.$http.post("bus/record/add", {
        params:this.addForm,
      }).then((res)=>{
        this.$message.success("添加成功！");
        //隐藏对话框
        this.addDialogVisible = false;
        //重新获取列表数据、统计数据
        this.getUserList();
        this.getRecordCount()
        //清除数据
        this.$refs.addFormRef.resetFields();
      }).catch((err)=>{}); 
      });
    },
    /* 筛选数据列表 */
    onSubmit() {
      this.getRecordCount();
      const params = {
        label: this.formInline.label.at(-1),
        attr: this.formInline.attr,
        account_id: this.formInline.account_id.at(-1),
        dept_id: this.formInline.dept_id,
        interval_time: this.formInline.interval_time.toString(),
      };

      this.$http.get("bus/record/list", {
       params: Object.assign(params, this.queryInfo),
      }).then((res)=>{
        this.userList = res.data;
        this.total = res.total;
      }).catch((err)=>{}); 
    },
    /* 下载模板 */
    download() {
      let a = document.createElement("a");
      a.href = "https://coin.gxshangjia.com/static/流水模板.xlsx";
      a.download = "流水模板.xlsx";
      a.click();
    },
    /* 上传模板 */
    uploadHttpRequest(param) {
      // console.log(param.file); //查看是否选取到文件

      let formData = new FormData(); //FormData对象，添加参数只能通过append('key', value)的形式添加
      // formData.append("id", this.tid); //添加id（这里是项目需要，根据个人情况选择是否添加）
      formData.append("record_excel", param.file); //添加文件对象
      // debugger;
      this.$http.post("bus/record/import", formData, {
          headers: {
            // Authorization: window.sessionStorage.getItem("token"), //配置header，我们上传的文件编码格式就是multipart/form-data
            "Content-Type": "multipart/form-data", //配置header，我们上传的文件编码格式就是multipart/form-data
          },
        })
        .then((res) => {
          this.$message.success("添加成功！");
          //重新获取列表数据
          this.getUserList();
        }).catch((err)=>{});
    },
  },
};
</script>

<style lang="less" scoped>
</style>
