<template>
  <div>
    <!-- 面包屑导航区域 -->
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/welcome' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>转账汇总</el-breadcrumb-item>
    </el-breadcrumb>
    <!-- 卡片视图区域 -->
    <el-card>
      <!-- 列表区域 -->
      <el-table
        :data="dataList"
        border
        stripe
        size="small"
      >
        <el-table-column label="公司" prop="comtitle" fixed></el-table-column>

        <el-table-column label="账户" prop="title"></el-table-column>
        <el-table-column
          label="欠款汇总"
          prop="account_inner"
        ></el-table-column>
        <el-table-column label="操作" width="180px">
          <template slot-scope="scope">
            <el-button
              type="primary"
              size="mini"
              @click="showDetailsDialog(scope.row.id)"
              >查看明细</el-button
            >
          </template>
        </el-table-column>
      </el-table>
    </el-card>

    <!-- 账户列表对话框1 -->
    <el-dialog title="账户汇总" :visible.sync="addDialogVisible" width="85%">
      <!-- 列表区域 -->
      <el-table :data="reportList" border stripe size="small">
        <el-table-column
          label="转出账户"
          prop="outAccTitle.title"
        ></el-table-column>
        <el-table-column
          label="转入账户"
          prop="intoAccTitle.title"
        ></el-table-column>
        <el-table-column label="欠款汇总" prop="qiankuan"></el-table-column>
        <el-table-column label="还款汇总" prop="huankuan"></el-table-column>
        <el-table-column label="欠款情况" prop="qingkaung"></el-table-column>
        <el-table-column label="操作" width="180px">
          <template slot-scope="scope">
            <el-button
              type="primary"
              size="mini"
              @click="showDetailsDialogmx(scope.row.out_account_id,scope.row.into_account_id,)"
              >明细</el-button
            >
          </template>
        </el-table-column>
      </el-table>
    </el-dialog>

<!-- 最终明细列表对话框2 -->
    <el-dialog title="明细" :visible.sync="addDialogVisiblemx" width="85%">
      <!-- 列表区域 -->
      <el-table :data="reportList" border stripe size="small">
        <el-table-column
          label="转出账户"
          prop="outAccTitle.title"
        ></el-table-column>
        <el-table-column
          label="转入账户"
          prop="intoAccTitle.title"
        ></el-table-column>
        <el-table-column label="金额" prop="account"></el-table-column>
        <el-table-column label="交易时间" prop="record_time"></el-table-column>
        <el-table-column label="摘要" prop="desc"></el-table-column>
      </el-table>
       <!-- 分页区域 -->
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="queryInfo.page"
        :page-sizes="[10, 20, 50, 500]"
        :page-size="queryInfo.list_rows"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </el-dialog>

  </div>
</template>

<script>
export default {
  data() {
    return {
      //数据列表
      comList: [],
      queryInfo: {
        page: 1,
        list_rows: 10,
      },
      dataList: [],
      totalList: [],
      reportList:[],
      total: 0,
      //控制添加用户对话框显示与隐藏
      addDialogVisible: false,
      addDialogVisiblemx: false,
    };
  },
  created() {
    this.getUserList();
  },
  methods: {
    /* 获取数据列表 */
    getUserList() {
      this.$http
        .get("bus/report/inner_list", {
          params: this.queryInfo,
        })
        .then((res) => {
          this.totalList = res.data;
          res.data.forEach((item) => {
            if (item.account == 0) {
              this.dataList.push({
                comtitle: item.title,
                title: "-",
                account_inner: "-",
              });
            }
            item.account.forEach((acitem) => {
              let obj1 = { comtitle: item.title };
              let obj2 = acitem;
              let obj = { ...obj1, ...obj2 };
              this.dataList.push(obj);
            });
          });
        })
        .catch((err) => {});
    },


    /* 点击查看详情按钮1*/
    showDetailsDialog(id) {
      this.addDialogVisible = true;
      this.getReportList(id);
    },

    /* 获取列表1 */
    getReportList(id) {
       const params = {
        page: 1,
        list_rows: 2000,
        account_id:id
      };
      this.$http
        .get("bus/report/inner_account", 
          {params}
        )
        .then((res) => {
          this.reportList = res.data;
        })
        .catch((err) => {});
    },

    /* 点击查看详情按钮2*/
    showDetailsDialogmx(out_id,into_id) {
      this.addDialogVisiblemx = true;
      this.queryInfo.out_id=out_id;
      this.queryInfo.into_id=into_id;
      this.getReportListmx();
    },

    /* 获取列表2 */
    getReportListmx() {
      console.log(this.queryInfo);
      let params =this.queryInfo;
      console.log(params);
      console.log(params.out_id);
      this.$http
        .get("bus/report/inner_account_mingxi", 
          {params}
        )
        .then((res) => {
          this.reportList = res.data;
          this.total = res.total;
        })
        .catch((err) => {});
    },

    /* 监听list_rows改变的事件 */
    handleSizeChange(newSize) {
      this.queryInfo.list_rows = newSize;
      this.getReportListmx();
    },

    /* 监听页码值改变的事件 */
    handleCurrentChange(newPage) {
      this.queryInfo.page = newPage;
      this.getReportListmx();
    },

  },
};
</script>

<style lang="less" scoped>
</style>
