<template>
  <div>
    <!-- 面包屑导航区域 -->
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/welcome' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>报表</el-breadcrumb-item>
      <el-breadcrumb-item>账户汇总</el-breadcrumb-item>
    </el-breadcrumb>
    <!-- 卡片视图区域 -->
    <el-card>
      <!-- 搜索与添加区域 -->
<el-form
          :inline="true"
          :model="formInline"
          ref="formInline"
          class="demo-form-inline"
          size="small"
        >
      <el-row type="flex" :gutter="20">

       <el-col :span="9">
               <el-form-item label="账户" prop="account_id">    
                  <el-cascader
                    placeholder="请选择（可搜索）"
                    v-model="formInline.account_id"
                    :options="comList"
                    :props="{ expandTrigger: 'hover',value: 'id',label:'title',children:'account' }"
                    filterable
                    style="width:340px"
                    @focus="selectAccountId"
                  >
                  </el-cascader>
                </el-select>
              </el-form-item>
          </el-col>
          <el-col :span="9">
            <el-form-item label="日期" prop="interval_time">
              <el-date-picker
                v-model="formInline.interval_time"
                type="daterange"
                align="right"
                unlink-panels
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
                :picker-options="pickerOptions"
                value-format="yyyy-MM-dd"
              >
              </el-date-picker> 
            </el-form-item>
          </el-col>
          <el-col :span="5">
            <el-form-item>
              <el-button type="primary" @click="onSubmit">查询</el-button>
              <el-button @click="resetForm('formInline')">重置</el-button>
            </el-form-item>
          </el-col>
      </el-row>
 </el-form>
      <!-- 用户列表区域 -->
      <el-table :data="dataList" border stripe size="small">
        <el-table-column label="公司" prop="company.title" width="250px"></el-table-column>
        <el-table-column label="账户" prop="title"></el-table-column>
        <el-table-column label="期初余额" prop="ago_balance"></el-table-column>
        <el-table-column label="收入汇总" prop="inc_exp_data[0].inc_total"></el-table-column>
        <el-table-column label="支出汇总" prop="inc_exp_data[1].exp_total"></el-table-column>
        <el-table-column label="余额" prop="now_balance"></el-table-column>
        <el-table-column label="收入笔数" prop="inc_exp_data[0].inc_count"></el-table-column>
        <el-table-column label="支出笔数" prop="inc_exp_data[1].exp_count"></el-table-column>
      </el-table>
      <!-- 分页区域 -->
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="queryInfo.page"
        :page-sizes="[10, 20, 50, 500]"
        :page-size="queryInfo.list_rows"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </el-card>
    
  </div>
</template>

<script>
export default {
  data() {
    return {
      formInline: {
        account_id: "",
        interval_time: "",
        page: 1,
        list_rows: 10,
      },
      //账户列表
      comList: [],
      //公司列表
      deptList: [],
      //时间选择器便捷选择功能
      pickerOptions: {
        shortcuts: [
          {
            text: "最近一周",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近一个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近三个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
              picker.$emit("pick", [start, end]);
            },
          },
        ],
      },

      queryInfo: {
        page: 1,
        list_rows: 10,
      },
      dataList: [],
      total: 0,
    };
  },
  created() {
    this.getDataList();
  },
  methods: {
    /* 获取数据列表 */
    getDataList() {
    this.$http.get("bus/report/account", {
        params: this.queryInfo,
      }).then((res)=>{
        this.dataList = res.data;
      this.total = res.total;
      }).catch((err)=>{}); 
    },

    /* 监听list_rows改变的事件 */
    handleSizeChange(newSize) {
      this.queryInfo.list_rows = newSize;
      this.getDataList();
    },

    /* 监听页码值改变的事件 */
    handleCurrentChange(newPage) {
      this.queryInfo.page = newPage;
      this.getDataList();
    },

    /* 重置表单 */
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },

    /*新增-获取账户信息 */
    selectAccountId() {
      const params = {
        page: 1,
        list_rows: 2000,
      };
    this.$http.get("bus/com/list", {
        params,
      }).then((res)=>{
        this.comList = res.data;
      }).catch((err)=>{}); 
    },

    /* 筛选数据列表 */
    onSubmit() {
      const params = {
        acc_id: this.formInline.account_id.at(-1),
        interval_time: this.formInline.interval_time.toString(),
        page: 1,
        list_rows: 10,
      };
     this.$http.get("bus/report/account", {
        params,
      }).then((res)=>{
        this.dataList = res.data;
      this.total = res.total;
      }).catch((err)=>{}); 
    },
  },
};
</script>

<style lang="less" scoped>
</style>
